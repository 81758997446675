import React from 'react';
import Overlay from 'components/commercetools-ui/atoms/overlay';

export interface CybersourceIframesProps {
    deviceDataCollectionUrl?: string;
    requestJwt?: string;
    stepUpUrl?: string;
    responseJwt?: string;
    showSubmitStepUpIframe?: boolean;
}
const CybersourceIframes: React.FC<CybersourceIframesProps> = ({
    deviceDataCollectionUrl,
    requestJwt,
    stepUpUrl,
    responseJwt,
    showSubmitStepUpIframe
}) => {
    return (
        <>
            <div>
                <iframe
                    id="cardinal_collection_iframe"
                    name="collectionIframe"
                    title="collectionIframe"
                    height="10"
                    width="10"
                    style={{ display: 'none' }}
                ></iframe>
                <form id="cardinal_collection_form" method="POST" target="collectionIframe" action={deviceDataCollectionUrl ?? ''}>
                    <input id="cardinal_collection_form_input" type="hidden" name="JWT" value={requestJwt ?? ''}></input>
                </form>
            </div>
            <div>
                <iframe
                    name="step-up-iframe"
                    title="step-up-iframe"
                    height="600"
                    width="400"
                    className="fixed left-1/2 top-1/2 z-[1000] -translate-x-1/2 -translate-y-1/2 bg-white"
                    style={{ display: showSubmitStepUpIframe ? 'block' : 'none' }}
                ></iframe>
                <form id="step-up-form" target="step-up-iframe" method="post" action={stepUpUrl ?? ''}>
                    {' '}
                    <input id="step-up-form-input" type="hidden" name="JWT" value={responseJwt ?? ''} />
                </form>
                {showSubmitStepUpIframe && <Overlay />}
            </div>
        </>
    );
};
export default CybersourceIframes;

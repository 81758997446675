import { useEffect, useState } from 'react';

const useTouchDevice = () => {
    const [isTouchDevice, setIsTouchDevice] = useState(false);

    useEffect(() => {
        setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0);
    }, []);

    return { isTouchDevice };
};

export default useTouchDevice;

import TagsActionType from './types/tagsActionType';
import type { GTMTag } from '../interfaces/gtmTag';

const clearHandler = (): GTMTag => {
    return {
        ecommerce: {},
        event: TagsActionType.CLEAR,
        _clear: true
    };
};

export default clearHandler;

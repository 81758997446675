'use client';

import React from 'react';
import type { Props as SpacerProps } from 'components/commercetools-ui/organisms/spacer';
import Spacer from 'components/commercetools-ui/organisms/spacer';
import type { TasticProps } from '../types';

const SpacerTastic = ({ data }: TasticProps<SpacerProps>) => {
    return <Spacer {...data} />;
};

export default SpacerTastic;

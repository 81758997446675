'use client';
import React from 'react';
import { useBrandSettingsContext } from 'providers/brand-settings';
import Image from 'frontastic/lib/image';
import type { FrontasticImage } from 'frontastic/lib/image/types';

export interface Props {
    logo: FrontasticImage;
}

const Footer: React.FC<Props> = ({ logo }) => {
    const { copyrightText } = useBrandSettingsContext();
    return (
        <div className="border-checkout-border border-t bg-checkout-footer-bg-mobile px-16 pb-56 md:bg-checkout-footer-bg lg:pb-36">
            <div className="flex justify-center p-20 lg:p-30">
                <div className="relative h-28 w-144 md:h-56 md:w-278">
                    <Image {...logo} style={{ objectFit: 'contain' }} priority />
                </div>
            </div>
            {copyrightText && <p className="text-center text-8 text-secondary-black md:text-12">{copyrightText}</p>}
        </div>
    );
};

export default Footer;

import type { Order } from '@wilm/shared-types/cart';
import type { Reference } from '@wilm/shared-frontend/types/reference';
import type { AddressExtended } from '../../backend/commerce-commercetools/interfaces/AddressExtended';

export enum SalesLinkStatus {
    EXIST = 'exist',
    TOKEN_NOT_EXIST = 'token_not_exist',
    TOKEN_EXPIRED = 'token_expired',
    CUSTOMER_NOT_EXIST = 'customer_not_exist'
}

export enum PaymentMethod {
    CREDIT_CARD = 'Credit/Debit Card',
    INVOICE = 'Invoice'
}

export enum AddressType {
    BILLIING = 'billing',
    SHIPPING = 'shipping'
}

export interface SalesLinkCustomerType {
    id: string; // CT customer ID
    name: string; // CT Full name
    firstName: string; // CT First name
    lastName: string; // CT Last name
    contactNumber: string; // Salesforce contact number
    contactId: string; // Salesforce contact ID
    email: string; // Email
    accountName: string; // Company name or full name
    isB2B: boolean; // Is B2B
    billingAddress: AddressExtended; // Billing address
    shippingAddress: AddressExtended;
    vatId: string;
    isCharity: boolean;
}

export interface SalesLinkInfoType {
    hash: string;
    status: SalesLinkStatus;
    createdAt?: string;
    expiresAt?: string;
    customer?: SalesLinkCustomerType;
    requestedByEmail?: string; // sales person that requested the link,
    unlockId?: string; // payment link unlock id
}

export interface CheckoutResponse {
    hash: string;
    learnersHash?: string;
    order: Order;
}

export interface SalesLinkSettings {
    invoiceEnabled?: boolean;
    cybersourceCardEnabled?: boolean;
    createInHouseEventsEnabled?: boolean;
    createInHouseSuccessMessage: string;
    createInHouseNoticeHeading: string;
    createInHouseNoticeMessage: string;
    createInHouseForRelatedProductMessage: string;
    createInHouseAdministrateLinkText: string;
    createInHouseDocumentationLinkText: string;
    createInHouseDocumentationLink?: Reference;
}

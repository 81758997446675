import React from 'react';

type Props = {
    className?: string;
    parentClassName?: string;
};

const MenuIcon: React.FC<Props> = ({ className, parentClassName }: Props) => (
    <span className={parentClassName}>
        <span className={className}></span>
    </span>
);

export default MenuIcon;

import type { FC } from 'react';
import React, { useMemo, useState } from 'react';
import type { Cart, Order } from '@wilm/shared-types/cart';
import Accordion from 'components/commercetools-ui/atoms/accordion';
import Typography from 'components/commercetools-ui/atoms/typography';
import useClassNames from 'helpers/hooks/useClassNames';
import { useFormat } from 'helpers/hooks/useFormat';
import useI18n from 'helpers/hooks/useI18n';
import mapCosts from 'helpers/utils/mapCosts';
import useBundle from 'frontastic/hooks/useBundle';
import AccordionButton from './accordion-button';
import OrderItem from '../../order-items-listing/order-item';

export interface Props {
    className?: string;
    order?: Order;
    cart?: Cart;
}

const SummaryAccordion: FC<Props> = ({ className, order, cart }) => {
    const data = { ...(order ?? cart) } as Cart;
    const { formatMessage: formatOrdersMessage } = useFormat({ name: 'orders' });

    const { currency } = useI18n();

    const [open, setOpen] = useState(false);

    const accordionClassNames = useClassNames(['block', className]);
    const buttonClassNames = useClassNames(['border-checkout-border w-full font-bold']);

    const toggleAccordion = () => setOpen(!open);

    const total = useMemo(
        () => (order ? mapCosts({ order, currency }).total : mapCosts({ reference: 'cart', cart, currency }).total),
        [cart, currency, order]
    );

    const { nonBundleItems: lineItems, bundleComponents, totalItems } = useBundle(data?.lineItems ?? []);

    const itemsLabel =
        totalItems === 1
            ? formatOrdersMessage({
                  id: 'item',
                  values: { total: totalItems },
                  defaultMessage: '{total} item'
              })
            : formatOrdersMessage({
                  id: 'items',
                  values: { total: totalItems },
                  defaultMessage: '{total} items'
              });

    return (
        <Accordion
            className={accordionClassNames}
            buttonClassName={buttonClassNames}
            iconClassName="w-24 m-0"
            customClosedButton={<AccordionButton open={open} total={total} />}
            onClick={toggleAccordion}
        >
            <Typography className="mb-6 font-bold">{itemsLabel}</Typography>
            <div className="pb-16">
                {lineItems?.map(lineItem => (
                    <OrderItem
                        key={lineItem.lineItemId}
                        bundleItems={bundleComponents?.[lineItem.lineItemId]}
                        lineItem={lineItem}
                        isLastItem={data?.lineItems?.indexOf(lineItem) === (data?.lineItems?.length ?? 0) - 1}
                    />
                ))}
            </div>
        </Accordion>
    );
};

export default SummaryAccordion;

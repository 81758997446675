import type { FC } from 'react';
import React from 'react';
import Typography from '../../typography';

interface QuantityProps {
    counterClassName: string;
    count: number;
    updateCartItem: (count: number) => void;
    disabledQuantity?: boolean;
    showQuantity?: boolean;
    showQuantityEdit?: boolean;
    inventoryCheckError?: string;
}

const Quantity: FC<QuantityProps> = ({
    counterClassName,
    count,
    updateCartItem,
    disabledQuantity = false,
    showQuantityEdit,
    showQuantity,
    inventoryCheckError
}) => {
    const disabledQuantityTextStyle = `${disabledQuantity ? 'text-input-disabled' : 'text-input-text'}`;

    const handleQuantityChangeClick = (newQuantity: number) => () => {
        updateCartItem(newQuantity);
    };

    return (
        <>
            {/* Quantity Display */}
            {showQuantity && (
                <div className="mt-10">
                    <span className="text-md">x {count}</span>
                </div>
            )}
            {/* Quantity Edit */}

            {showQuantityEdit && (
                <div className="mt-10">
                    <div
                        className={`${counterClassName} ${
                            disabledQuantity &&
                            'border-input-disabled text-input-disabled hover:cursor-not-allowed hover:border-input-disabled'
                        }`}
                    >
                        <button
                            disabled={disabledQuantity}
                            onClick={handleQuantityChangeClick(count - 1)}
                            className={`cursor-[inherit] py-3 pl-12 ${disabledQuantityTextStyle}`}
                        >
                            -
                        </button>
                        <span className={`py-3 text-md ${disabledQuantityTextStyle}`}>{count}</span>
                        <button
                            disabled={disabledQuantity}
                            onClick={handleQuantityChangeClick(count + 1)}
                            className={`cursor-[inherit] py-3 pr-12 ${disabledQuantityTextStyle}`}
                        >
                            +
                        </button>
                    </div>
                </div>
            )}

            {/* Inventory Check Error */}
            {inventoryCheckError && (
                <Typography className="mt-12 text-sm leading-tight text-input-error" as="p">
                    {inventoryCheckError}
                </Typography>
            )}
        </>
    );
};

export default Quantity;

import type { FC } from 'react';
import type { Account } from '@wilm/shared-types/account';
import Typography from 'components/commercetools-ui/atoms/typography';
import type { TypographyProps } from 'components/commercetools-ui/atoms/typography/types';
import { useFormat } from 'helpers/hooks/useFormat';
import Markdown from '../../markdown';

type ThankYouHeaderProps = {
    email?: Account['email'];
    emailNotRecievedContent: string;
};

const ThankYouHeader: FC<ThankYouHeaderProps> = ({ email, emailNotRecievedContent }) => {
    const { formatMessage } = useFormat({ name: 'thank-you' });
    const subtitleProps: TypographyProps = {
        fontSize: 14,
        className: 'text-primary-black md:text-16'
    };

    return (
        <div className="grid justify-items-center gap-24 border-b border-neutral-400 pb-24 pt-16 md:border-b-0 lg:justify-items-start lg:p-0">
            {/* Title */}
            <Typography as="h3" asSkeleton={!email} className="text-primary-black lg:text-22" fontSize={20} data-cy="thank-you-header">
                {formatMessage({ id: 'thank.for.order', defaultMessage: 'Thank you for your order' })}
            </Typography>

            {/* Subtitle */}
            <div className="flex flex-col items-center md:items-start">
                <div className="flex flex-col items-center md:flex-row">
                    <Typography asSkeleton={!email} {...subtitleProps}>
                        {formatMessage({ id: 'email.sent', defaultMessage: 'An email confirmation has been sent to' })}
                    </Typography>
                    <Typography asSkeleton={!email} {...subtitleProps} className="mx-5 text-primary-black md:text-16">
                        {email ?? 'example@email.com'}
                    </Typography>
                </div>
                <div className="flex flex-col items-center md:items-start">
                    <Typography asSkeleton={!email} {...subtitleProps} className="text-center md:text-left md:text-16">
                        {formatMessage({
                            id: 'email.few.minutes',
                            defaultMessage: 'Please be aware that the confirmation email might take a few minutes to be delivered.'
                        })}
                    </Typography>
                    {emailNotRecievedContent ? (
                        <Markdown markdown={emailNotRecievedContent} />
                    ) : (
                        <Typography asSkeleton={!email} {...subtitleProps} className="text-center md:text-left md:text-16">
                            {formatMessage({
                                id: 'email.not.received',
                                defaultMessage:
                                    'In case the email is not received, please follow this link to contact our Customer Support.'
                            })}
                        </Typography>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ThankYouHeader;

import React, { useState } from 'react';
import Button from 'components/commercetools-ui/atoms/button';
import Drawer from 'components/commercetools-ui/atoms/drawer';
import type { MenuState } from 'components/commercetools-ui/organisms/header/utility-section/components/slide-out';
import Slideout from 'components/commercetools-ui/organisms/header/utility-section/components/slide-out';
import CartIcon from 'components/icons/cart';
import type { Reference } from 'types/reference';
import { useCart } from 'frontastic';
import type { ImageProps } from 'frontastic/lib/image/types';
import AccountButton from './components/account-button';

export interface Props {
    emptyCartTitle: string;
    emptyCartImage: ImageProps;
    accountLabel?: string;
    accountLabelLogged?: string;
    cartLabel?: string;
    accountLinkLabel?: string;
    accountLink?: Reference;
    wishlistLinkLabel?: string;
    wishlistLink?: Reference;
    accountLogoutLabel?: string;
    resetFlag: () => void;
}

const UtilitySection: React.FC<Props> = ({
    emptyCartTitle,
    emptyCartImage,
    accountLabel,
    accountLabelLogged,
    cartLabel,
    accountLinkLabel,
    accountLink,
    accountLogoutLabel,
    wishlistLinkLabel,
    wishlistLink,
    resetFlag
}) => {
    const { totalItems: totalCartItems } = useCart();

    const [menuState, setMenuState] = useState<MenuState>();
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const onCartClicked = () => {
        setIsDrawerOpen(true);
        setMenuState('cart');
    };

    const onCloseClicked = () => {
        setIsDrawerOpen(false);
        resetFlag();
    };

    return (
        <div className="flex items-center">
            <div className="flex justify-center">
                <AccountButton
                    accountLabel={accountLabel}
                    accountLabelLogged={accountLabelLogged}
                    accountLinkLabel={accountLinkLabel}
                    accountLink={accountLink}
                    accountLogoutLabel={accountLogoutLabel}
                    wishlistLinkLabel={wishlistLinkLabel}
                    wishlistLink={wishlistLink}
                    resetFlag={resetFlag}
                />
                <Button variant="ghost" size="fit" onClick={onCartClicked} className="mx-5 md:mx-10" data-cy="cart-icon">
                    <CartIcon
                        totalCartItems={totalCartItems}
                        counterClassName="absolute right-0 top-0 h-10 md:h-12 w-10 md:w-12 rounded-full bg-icons-cart"
                        cartLabel={cartLabel}
                        className="relative flex w-30 flex-col items-center hover:text-icons-cart-hover md:w-42"
                        iconClassName="w-24 md:w-37"
                    />
                </Button>
            </div>

            <Drawer isOpen={isDrawerOpen} direction="right" className="w-[90%] max-w-380 bg-white" onClose={() => setIsDrawerOpen(false)}>
                <Slideout
                    state={menuState}
                    onClose={onCloseClicked}
                    changeState={newState => setMenuState(newState)}
                    emptyCartTitle={emptyCartTitle}
                    emptyCartImage={emptyCartImage}
                    resetFlag={resetFlag}
                />
            </Drawer>
        </div>
    );
};

export default UtilitySection;

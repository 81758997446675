'use client';

import Footer from 'components/commercetools-ui/organisms/footer/simple';
import type { FrontasticImage } from 'frontastic/lib/image/types';
import type { TasticProps } from 'frontastic/tastics/types';

interface SimpleFooterTasticProps {
    logo: FrontasticImage;
}

const SimpleFooterTastic = (props: TasticProps<SimpleFooterTasticProps>) => {
    return <Footer logo={props.data.logo} />;
};
export default SimpleFooterTastic;

import type { FC } from 'react';
import React, { useMemo } from 'react';
import type { Variant } from '@commercetools/frontend-domain-types/product';
import type { Product } from '@wilm/shared-types/product';
import { AttributeEnum } from '@wilm/shared-types/product/Attributes';
import { BundleMode } from '@wilm/shared-types/product/Bundle';
import type { LineItemAddToCartAttributes, BundleLineItemError } from '@wilm/shared-types/sales-link/SalesLinkCart';
import Button from 'components/commercetools-ui/atoms/button';
import SelectGroupContent from 'components/sales-link/organisms/content/products/bundle-component-selector/select-group-content';
import BundleComponentSelectorTile from 'components/sales-link/organisms/content/products/bundle-component-selector/tile';
import { useFormat } from 'helpers/hooks/useFormat';

interface BundleComponentSelectorProps {
    mode: BundleMode;
    bundleOptions: { label: string; value: string }[];
    variant: Variant;
    bundleErrors: BundleLineItemError;
    onAddToCart: (variant: Variant, attributes?: LineItemAddToCartAttributes) => void;
}

const BundleComponentSelector: FC<BundleComponentSelectorProps> = ({ mode, bundleOptions, variant, bundleErrors, onAddToCart }) => {
    const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
    const [selectedAttributes, setSelectedAttributes] = React.useState<LineItemAddToCartAttributes>({
        selectedBundleComponentVariantSKUs: {},
        selectedCommencementDate: ''
    });

    const [selectedGroupError, setSelectedGroupError] = React.useState<string>('');

    const components: Product[] = variant.attributes?.bundleComponentsProducts;

    const dropdownBundleOptions = useMemo(() => {
        const options = bundleOptions?.map(option => {
            return {
                ...option,
                value: option.value
            };
        });

        return [{ label: ' -- Select --', value: '' }, ...options];
    }, [bundleOptions]);

    const selectedProductVariants = useMemo(() => {
        const selectedProductVariants: { productId: string; productName: string; variant: Variant }[] = [];
        for (const [productId, sku] of Object.entries(selectedAttributes.selectedBundleComponentVariantSKUs)) {
            const component = components?.find(component => component.productId === productId);
            const variant = component?.variants?.find(variant => variant.sku === sku);
            if (variant) {
                selectedProductVariants.push({ variant, productId: component?.productId ?? '', productName: component?.name ?? '' });
            }
        }
        return selectedProductVariants;
    }, [selectedAttributes.selectedBundleComponentVariantSKUs, components]);

    const hasOosInSelectedGroup = selectedProductVariants.some(variant => !variant.variant.isOnStock);

    const onBundleComponentSelect = (selectedProduct: Product, selectedVariant: Variant) => {
        if (mode === BundleMode.COMMENCEMENT_DATE) {
            setSelectedAttributes({
                ...selectedAttributes,
                selectedBundleComponentVariantSKUs: {
                    [selectedProduct.productId!]: selectedVariant.sku
                },
                selectedCommencementDate: selectedVariant.attributes?.startDate.split('T')[0]
            });
        } else if (mode === BundleMode.SELECT_COMPONENTS) {
            setSelectedAttributes({
                ...selectedAttributes,
                selectedBundleComponentVariantSKUs: {
                    ...(selectedAttributes.selectedBundleComponentVariantSKUs as Record<string, string>),
                    [selectedProduct.productId!]: selectedVariant.sku
                }
            });
        }
    };

    const handleAddToCart = () => {
        if (mode === BundleMode.SELECT_GROUP && !Object.keys(selectedAttributes.selectedBundleComponentVariantSKUs).length) {
            setSelectedGroupError('Please select a group');
            return;
        }
        onAddToCart(variant, selectedAttributes);
    };

    return (
        <div className={'max-h-screen overflow-auto p-40'}>
            {BundleMode.SELECT_GROUP === mode && dropdownBundleOptions && (
                <SelectGroupContent
                    dropdownBundleOptions={dropdownBundleOptions}
                    selectedGroupError={selectedGroupError}
                    selectedAttributes={selectedAttributes}
                    setSelectedAttributes={setSelectedAttributes}
                    selectedProductVariants={selectedProductVariants}
                    components={components}
                />
            )}
            {BundleMode.SELECT_GROUP !== mode &&
                components.map((component: Product) => (
                    <BundleComponentSelectorTile
                        key={component.productId}
                        product={component}
                        errors={bundleErrors}
                        selectedSkus={selectedAttributes.selectedBundleComponentVariantSKUs as Record<string, string>}
                        attributesToShow={[
                            AttributeEnum.SHORT_DESCRIPTION,
                            AttributeEnum.START_DATE,
                            AttributeEnum.END_DATE,
                            AttributeEnum.ADMINISTRATE_EVENT_COHORT,
                            AttributeEnum.PRICE
                        ]}
                        onSelect={onBundleComponentSelect}
                    />
                ))}
            <div className="flex justify-end">
                <Button variant="primary" onClick={handleAddToCart} disabled={hasOosInSelectedGroup && mode === BundleMode.SELECT_GROUP}>
                    Add to cart
                </Button>
            </div>
            <div className="flex justify-end">
                {bundleErrors?.error.code && <div className="text-red-500">{formatErrorMessage({ id: bundleErrors.error.code })}</div>}
            </div>
        </div>
    );
};

export default BundleComponentSelector;

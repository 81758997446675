import React, { useMemo } from 'react';
import CloseIcon from '@heroicons/react/24/outline/XMarkIcon';
import Cart from 'components/commercetools-ui/organisms/cart-slideout';
import CartIcon from 'components/icons/cart';
import useClassNames from 'helpers/hooks/useClassNames';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic';
import type { ImageProps } from 'frontastic/lib/image/types';

export type MenuState = 'wishlist' | 'cart';

export interface SlideOutProps {
    state?: MenuState;
    changeState?: (newState?: MenuState) => void;
    onClose?: () => void;
    emptyCartTitle: string;
    emptyCartImage: ImageProps;
    enableCartState?: boolean;
    resetFlag: () => void;
}

const Slideout: React.FC<SlideOutProps> = ({
    state,
    changeState,
    onClose,
    emptyCartTitle,
    emptyCartImage,
    enableCartState = true,
    resetFlag
}) => {
    const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
    const { formatMessage: formatWishlistMessage } = useFormat({ name: 'wishlist' });

    const { totalItems: totalCartItems } = useCart();

    const title = useMemo(() => {
        switch (state) {
            case 'cart':
                return formatCartMessage({ id: 'myCart', defaultMessage: 'My Cart' });
            case 'wishlist':
                return formatWishlistMessage({ id: 'my.wishlist', defaultMessage: 'My Wishlist' });
        }
    }, [formatCartMessage, formatWishlistMessage, state]);

    const cartClassName = useClassNames(['w-34', state === 'cart' ? 'bg-secondary-grey ease-out' : 'bg-transparent ease-in']);

    return (
        <>
            <div
                data-cy="cart-active"
                className="border-checkout-border flex items-center justify-between border-b py-16 pl-12 pr-8 md:pl-22 md:pr-18"
            >
                <h3 className="text-20">{title}</h3>
                <div className="flex items-center gap-14">
                    {enableCartState && (
                        <button
                            className="relative h-full cursor-pointer transition hover:opacity-80"
                            title="cart"
                            onClick={() => changeState?.('cart')}
                        >
                            <div className={cartClassName} />

                            <CartIcon
                                className="w-29 md:w-36"
                                totalCartItems={totalCartItems}
                                iconClassName="w-27 md:w-32"
                                counterClassName="absolute right-1 md:right-0 top-0 md:top-3 h-10 w-10 rounded-full bg-icons-cart"
                            />
                        </button>
                    )}
                    <button
                        onClick={onClose}
                        className="cursor-pointer transition hover:opacity-80"
                        title="close-cart"
                        data-cy="close-cart"
                    >
                        <CloseIcon className="w-w-38 stroke-minicart-close" />
                    </button>
                </div>
            </div>
            <Cart emptyStateImage={emptyCartImage} emptyStateTitle={emptyCartTitle} resetFlag={resetFlag} />
        </>
    );
};

export default Slideout;

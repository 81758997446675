import React from 'react';

type Props = {
    className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
    <svg className={className} width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_1376_1626" maskUnits="userSpaceOnUse" x="1" y="1" width="34" height="34">
            <circle cx="18" cy="18" r="17" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1376_1626)">
            <circle cx="18" cy="36" r="13" stroke="#6D3049" strokeWidth="2" />
        </g>
        <circle cx="18" cy="18" r="17" stroke="#6D3049" strokeWidth="2" />
        <circle cx="18" cy="16" r="6" stroke="#6D3049" strokeWidth="2" />
    </svg>
);

export default Icon;

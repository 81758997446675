import { useCallback } from 'react';
import type { Account } from '@wilm/shared-types/account';
import type { PaymentObject } from '@wilm/shared-types/cart/Payment';
import { sdk } from 'sdk';
import type { Order } from '@wilm/shared-types/cart';

const usePayment = () => {
    const actionController = 'cart';
    const makeInvoicePayment = useCallback(async (centAmount: number, currencyCode: string) => {
        const payment = await sdk.callAction({
            actionName: `${actionController}/addPaymentByInvoice`,
            payload: {
                payment: {
                    amountPlanned: {
                        currencyCode: currencyCode,
                        centAmount: centAmount
                    }
                }
            }
        });
        return payment;
    }, []);

    const makeInstalmentPayment = useCallback(async (centAmount: number, currencyCode: string, paymentInstalmentOption: number) => {
        const payment = await sdk.callAction({
            actionName: `${actionController}/addPaymentByInstalment`,
            payload: {
                payment: {
                    amountPlanned: {
                        currencyCode: currencyCode,
                        centAmount: centAmount
                    }
                },
                paymentInstalmentOption
            }
        });
        return payment;
    }, []);

    const paymentUpdateWithTransientToken = useCallback(async (token: string, paymentId: string) => {
        const response = await sdk.callAction({
            actionName: `${actionController}/updatePaymentWithTransientToken`,
            payload: {
                token,
                paymentId
            }
        });
        const payment = (response.isError ? null : response.data) as PaymentObject;

        return payment;
    }, []);

    const tokenizeUser = useCallback(async (token: string, paymentId: string) => {
        const response = await sdk.callAction({
            actionName: `${actionController}/tokenizeUser`,
            payload: {
                token,
                paymentId,
                tokenizeCard: true
            }
        });
        const account = (response.isError ? null : response.data) as Account;

        return account;
    }, []);

    const addInitialPaymentToGetTokenContext = useCallback(async (centAmount: number, currencyCode: string) => {
        const response = await sdk.callAction({
            actionName: `${actionController}/addPaymentToGetTokenContext`,
            payload: {
                payment: {
                    amountPlanned: {
                        currencyCode: currencyCode,
                        centAmount: centAmount
                    }
                }
            }
        });

        const payment = (response.isError ? null : response.data) as PaymentObject;

        return payment;
    }, []);

    const addPaymentWithToken = useCallback(async (centAmount: number, currencyCode: string) => {
        const response = await sdk.callAction({
            actionName: `${actionController}/addPaymentWithCustomerToken`,
            payload: {
                payment: {
                    amountPlanned: {
                        currencyCode: currencyCode,
                        centAmount: centAmount
                    }
                }
            }
        });

        const payment = (response.isError ? null : response.data) as PaymentObject;

        return payment;
    }, []);

    const updatePaymentToTriggerAuthFlow = useCallback(async (paymentId: string) => {
        const response = await sdk.callAction({
            actionName: `${actionController}/updatePaymentWithAccountCardData`,
            payload: {
                paymentId: paymentId
            }
        });

        const payment = (response.isError ? null : response.data) as PaymentObject;
        return payment;
    }, []);

    const updatePaymentWithClientUserData = useCallback(async (paymentId: string, order?: Order) => {
        const response = await sdk.callAction({
            actionName: `${actionController}/updatePaymentWithClientUserData`,
            payload: {
                acceptHeader: '*/*',
                userAgentHeader: window.navigator.userAgent,
                paymentId: paymentId,
                order: order
            }
        });

        const payment = (response.isError ? null : response.data) as PaymentObject;
        return payment;
    }, []);

    const updateWithThreeDsTransactionId = useCallback(async (transactionId: string, paymentId: string) => {
        const response = await sdk.callAction<{ payment: PaymentObject; error: boolean; errorMsg: string | undefined }>({
            actionName: `${actionController}/updateWithThreeDsTransactionId`,
            payload: {
                transactionId: transactionId,
                paymentId: paymentId
            }
        });

        if (response.isError) {
            return { payment: null, error: true, errorMsg: response.error.message };
        } else {
            return { payment: response.data.payment, error: response.data.error, errorMsg: response.data.errorMsg };
        }
    }, []);

    return {
        makeInvoicePayment,
        makeInstalmentPayment,
        paymentUpdateWithTransientToken,
        tokenizeUser,
        addInitialPaymentToGetTokenContext,
        addPaymentWithToken,
        updatePaymentToTriggerAuthFlow,
        updatePaymentWithClientUserData,
        updateWithThreeDsTransactionId
    };
};

export default usePayment;

export enum Brand {
    BOND_SOLON = 'bond-solon',
    ICA = 'ica',
    CLTI = 'clti',
    MERCIA = 'mercia'
}

export const BrandLa1Map = {
    [Brand.ICA]: ['ICT', 'ICA', 'ICD', 'ICS', 'ICM'],
    [Brand.CLTI]: ['CLI', 'CHK'],
    [Brand.MERCIA]: ['MCA', 'MRI'],
    [Brand.BOND_SOLON]: ['BSL']
};

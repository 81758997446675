import { useCallback } from 'react';
import i18nConfig from 'i18n.config';
import { useI18n } from 'providers/i18n';
import type { TranslationOptions } from 'providers/i18n/hooks/useTranslation/types';

const useTranslation = () => {
    const { translations } = useI18n();

    const locale = i18nConfig.defaultLocale;

    const translate = useCallback(
        (token: string, { values = {}, defaultMessage = '' }: TranslationOptions = {}) => {
            // Token will be like this {namespace}.{c1}.{c2}... (.eg: common.say.hi)

            const fallbackMessage = defaultMessage || token || '';

            if (!translations) return fallbackMessage;

            const firstDotIndex = token.indexOf('.');

            if (firstDotIndex === -1) return fallbackMessage;

            const [namespace, key] = [token.substring(0, firstDotIndex), token.substring(firstDotIndex + 1)];

            let message = translations[locale]?.[namespace]?.[key] ?? fallbackMessage;

            for (const placeholder of Object.keys(values)) {
                message = message.replace(new RegExp(`{${placeholder}}`, 'g'), values[placeholder].toString());
            }

            return message;
        },
        [translations, locale]
    );

    return { translate };
};

export default useTranslation;

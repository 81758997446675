export const states = {
    CN: [
        {
            value: 'BJ',
            label: 'Beijing'
        },
        {
            value: 'TJ',
            label: 'Tianjin'
        },
        {
            value: 'HE',
            label: 'Hebei'
        },
        {
            value: 'SX',
            label: 'Shanxi'
        },
        {
            value: 'NM',
            label: 'Nei Mongol'
        },
        {
            value: 'LN',
            label: 'Liaoning'
        },
        {
            value: 'JL',
            label: 'Jilin'
        },
        {
            value: 'HL',
            label: 'Heilongjiang'
        },
        {
            value: 'SH',
            label: 'Shanghai'
        },
        {
            value: 'JS',
            label: 'Jiangsu'
        },
        {
            value: 'ZJ',
            label: 'Zhejiang'
        },
        {
            value: 'AH',
            label: 'Anhui'
        },
        {
            value: 'FJ',
            label: 'Fujian'
        },
        {
            value: 'JX',
            label: 'Jiangxi'
        },
        {
            value: 'SD',
            label: 'Shandong'
        },
        {
            value: 'HA',
            label: 'Henan'
        },
        {
            value: 'HB',
            label: 'Hubei'
        },
        {
            value: 'HN',
            label: 'Hunan'
        },
        {
            value: 'GD',
            label: 'Guangdong'
        },
        {
            value: 'GX',
            label: 'Guangxi'
        },
        {
            value: 'HI',
            label: 'Hainan'
        },
        {
            value: 'CQ',
            label: 'Chongqing'
        },
        {
            value: 'SC',
            label: 'Sichuan'
        },
        {
            value: 'GZ',
            label: 'Guizhou'
        },
        {
            value: 'YN',
            label: 'Yunnan'
        },
        {
            value: 'XZ',
            label: 'Xizang'
        },
        {
            value: 'SN',
            label: 'Shaanxi'
        },
        {
            value: 'GS',
            label: 'Gansu'
        },
        {
            value: 'QH',
            label: 'Qinghai'
        },
        {
            value: 'NX',
            label: 'Ningxia'
        },
        {
            value: 'XJ',
            label: 'Xinjiang'
        },
        {
            value: 'TW',
            label: 'Chinese Taipei'
        },
        {
            value: 'MO',
            label: 'Macao'
        }
    ],
    US: [
        {
            value: 'AL',
            label: 'Alabama'
        },
        {
            value: 'AK',
            label: 'Alaska'
        },
        {
            value: 'AS',
            label: 'American Samoa'
        },
        {
            value: 'AZ',
            label: 'Arizona'
        },
        {
            value: 'AR',
            label: 'Arkansas'
        },
        {
            value: 'CA',
            label: 'California'
        },
        {
            value: 'CO',
            label: 'Colorado'
        },
        {
            value: 'CT',
            label: 'Connecticut'
        },
        {
            value: 'DE',
            label: 'Delaware'
        },
        {
            value: 'DC',
            label: 'District of Columbia'
        },
        {
            value: 'FM',
            label: 'Federated States of Micronesia '
        },
        {
            value: 'FL',
            label: 'Florida'
        },
        {
            value: 'GA',
            label: 'Georgia'
        },
        {
            value: 'GU',
            label: 'Guam'
        },
        {
            value: 'HI',
            label: 'Hawaii'
        },
        {
            value: 'ID',
            label: 'Idaho'
        },
        {
            value: 'IL',
            label: 'Illinois'
        },
        {
            value: 'IN',
            label: 'Indiana'
        },
        {
            value: 'IA',
            label: 'Iowa'
        },
        {
            value: 'KS',
            label: 'Kansas'
        },
        {
            value: 'KY',
            label: 'Kentucky'
        },
        {
            value: 'LA',
            label: 'Louisiana'
        },
        {
            value: 'ME',
            label: 'Maine'
        },
        {
            value: 'MH',
            label: 'Marshall Islands'
        },
        {
            value: 'MD',
            label: 'Maryland'
        },
        {
            value: 'MA',
            label: 'Massachusetts'
        },
        {
            value: 'MI',
            label: 'Michigan'
        },
        {
            value: 'MS',
            label: 'Mississippi'
        },
        {
            value: 'MA',
            label: 'Missouri'
        },
        {
            value: 'MO',
            label: 'Montana'
        },
        {
            value: 'NE',
            label: 'Nebraska'
        },
        {
            value: 'NV',
            label: 'Nevada'
        },
        {
            value: 'NH',
            label: 'New Hampshire'
        },
        {
            value: 'NJ',
            label: 'New Jersey'
        },
        {
            value: 'NM',
            label: 'New Mexico'
        },
        {
            value: 'NY',
            label: 'New York'
        },
        {
            value: 'NC',
            label: 'North Carolina'
        },
        {
            value: 'ND',
            label: 'North Dakota'
        },
        {
            value: 'MP',
            label: 'Northern Mariana Islands'
        },
        {
            value: 'OH',
            label: 'Ohio'
        },
        {
            value: 'OK',
            label: 'Oklahoma'
        },
        {
            value: 'OR',
            label: 'Oregon'
        },
        {
            value: 'PW',
            label: 'Palau'
        },
        {
            value: 'PA',
            label: 'Pennsylvania'
        },
        {
            value: 'PR',
            label: 'Puerto Rico'
        },
        {
            value: 'RI',
            label: 'Rhode Island'
        },
        {
            value: 'SC',
            label: 'South Carolina'
        },
        {
            value: 'SD',
            label: 'South Dakota'
        },
        {
            value: 'TN',
            label: 'Tennessee'
        },
        {
            value: 'TX',
            label: 'Texas'
        },
        {
            value: 'UT',
            label: 'Utah'
        },
        {
            value: 'VT',
            label: 'Vermont'
        },
        {
            value: 'VI',
            label: 'Virgin Islands'
        },
        {
            value: 'WA',
            label: 'Washington'
        },
        {
            value: 'WV',
            label: 'West Virginia'
        },
        {
            value: 'WI',
            label: 'Wisconsin'
        },
        {
            value: 'WY',
            label: 'Wyoming'
        }
    ],
    CA: [
        {
            value: 'AB',
            label: 'Alberta'
        },
        {
            value: 'BC',
            label: 'British Columbia'
        },
        {
            value: 'MB',
            label: 'Manitoba'
        },
        {
            value: 'NB',
            label: 'New Brunswick'
        },
        {
            value: 'NL',
            label: 'Newfoundland and Labrador'
        },
        {
            value: 'NT',
            label: 'Northwest Territories'
        },
        {
            value: 'NS',
            label: 'Nova Scotia'
        },
        {
            value: 'NU',
            label: 'Nunavut'
        },
        {
            value: 'ON',
            label: 'Ontario'
        },
        {
            value: 'PE',
            label: 'Prince Edward Island'
        },
        {
            value: 'QC',
            label: 'Quebec'
        },
        {
            value: 'SK',
            label: 'Saskatchewan'
        },
        {
            value: 'YT',
            label: 'Yukon'
        }
    ],
    BR: [
        {
            value: 'AC',
            label: 'Acre'
        },
        {
            value: 'AL',
            label: 'Alagoas'
        },
        {
            value: 'AM',
            label: 'Amazonas'
        },
        {
            value: 'AP',
            label: 'Amapá'
        },
        {
            value: 'BA',
            label: 'Bahia'
        },
        {
            value: 'CE',
            label: 'Ceará'
        },
        {
            value: 'DF',
            label: 'Distrito Federal'
        },
        {
            value: 'ES',
            label: 'Espírito Santo'
        },
        {
            value: 'GO',
            label: 'Goiás'
        },
        {
            value: 'MA',
            label: 'Maranhão'
        },
        {
            value: 'MG',
            label: 'Minas Gerais'
        },
        {
            value: 'MS',
            label: 'Mato Grosso do Sul'
        },
        {
            value: 'MT',
            label: 'Mato Grosso'
        },
        {
            value: 'PA',
            label: 'Pará'
        },
        {
            value: 'PB',
            label: 'Paraíba'
        },
        {
            value: 'PE',
            label: 'Pernambuco'
        },
        {
            value: 'PI',
            label: 'Piauí'
        },
        {
            value: 'PR',
            label: 'Paraná'
        },
        {
            value: 'RJ',
            label: 'Rio de Janeiro'
        },
        {
            value: 'RN',
            label: 'Rio Grande do Norte'
        },
        {
            value: 'RO',
            label: 'Rondônia'
        },
        {
            value: 'RR',
            label: 'Roraima'
        },
        {
            value: 'RS',
            label: 'Rio Grande do Sul'
        },
        {
            value: 'SC',
            label: 'Santa Catarina'
        },
        {
            value: 'SE',
            label: 'Sergipe'
        },
        {
            value: 'SP',
            label: 'São Paulo'
        },
        {
            value: 'TO',
            label: 'Tocantins'
        }
    ],
    IN: [
        {
            value: 'AN',
            label: 'Andaman and Nicobar Islands'
        },
        {
            value: 'AP',
            label: 'Andhra Pradesh'
        },
        {
            value: 'AR',
            label: 'Arunachal Pradesh'
        },
        {
            value: 'AS',
            label: 'Assam'
        },
        {
            value: 'BR',
            label: 'Bihar'
        },
        {
            value: 'CH',
            label: 'Chandigarh'
        },
        {
            value: 'CT',
            label: 'Chhattisgarh'
        },
        {
            value: 'DD',
            label: 'Daman and Diu'
        },
        {
            value: 'DL',
            label: 'Delhi'
        },
        {
            value: 'DN',
            label: 'Dadra and Nagar Haveli'
        },
        {
            value: 'GA',
            label: 'Goa'
        },
        {
            value: 'GJ',
            label: 'Gujarat'
        },
        {
            value: 'HP',
            label: 'Himachal Pradesh'
        },
        {
            value: 'HR',
            label: 'Haryana'
        },
        {
            value: 'JH',
            label: 'Jharkhand'
        },
        {
            value: 'JK',
            label: 'Jammu and Kashmir'
        },
        {
            value: 'KA',
            label: 'Karnataka'
        },
        {
            value: 'KL',
            label: 'Kerala'
        },
        {
            value: 'LD',
            label: 'Lakshadweep'
        },
        {
            value: 'MH',
            label: 'Maharashtra'
        },
        {
            value: 'ML',
            label: 'Meghalaya'
        },
        {
            value: 'MN',
            label: 'Manipur'
        },
        {
            value: 'MP',
            label: 'Madhya Pradesh'
        },
        {
            value: 'MZ',
            label: 'Mizoram'
        },
        {
            value: 'NL',
            label: 'Nagaland'
        },
        {
            value: 'OR',
            label: 'Odisha'
        },
        {
            value: 'PB',
            label: 'Punjab'
        },
        {
            value: 'PY',
            label: 'Puducherry'
        },
        {
            value: 'RJ',
            label: 'Rajasthan'
        },
        {
            value: 'SK',
            label: 'Sikkim'
        },
        {
            value: 'TN',
            label: 'Tamil Nadu'
        },
        {
            value: 'TR',
            label: 'Tripura'
        },
        {
            value: 'UP',
            label: 'Uttar Pradesh'
        },
        {
            value: 'UT',
            label: 'Uttarakhand'
        }
    ],
    MX: [
        {
            value: 'AG',
            label: 'Aguascalientes'
        },
        {
            value: 'BC',
            label: 'Baja California'
        },
        {
            value: 'BS',
            label: 'Baja California Sur'
        },
        {
            value: 'CH',
            label: 'Chihuahua'
        },
        {
            value: 'CL',
            label: 'Colima'
        },
        {
            value: 'CM',
            label: 'Campeche'
        },
        {
            value: 'CO',
            label: 'Coahuila'
        },
        {
            value: 'CS',
            label: 'Chiapas'
        },
        {
            value: 'DF',
            label: 'Federal District'
        },
        {
            value: 'DG',
            label: 'Durango'
        },
        {
            value: 'GR',
            label: 'Guerrero'
        },
        {
            value: 'GT',
            label: 'Guanajuato'
        },
        {
            value: 'HG',
            label: 'Hidalgo'
        },
        {
            value: 'JA',
            label: 'Jalisco'
        },
        {
            value: 'ME',
            label: 'Mexico State'
        },
        {
            value: 'MI',
            label: 'Michoacán'
        },
        {
            value: 'MO',
            label: 'Morelos'
        },
        {
            value: 'NA',
            label: 'Nayarit'
        },
        {
            value: 'NL',
            label: 'Nuevo León'
        },
        {
            value: 'OA',
            label: 'Oaxaca'
        },
        {
            value: 'PB',
            label: 'Puebla'
        },
        {
            value: 'QE',
            label: 'Querétaro'
        },
        {
            value: 'QR',
            label: 'Quintana Roo'
        },
        {
            value: 'SI',
            label: 'Sinaloa'
        },
        {
            value: 'SL',
            label: 'San Luis Potosí'
        },
        {
            value: 'SO',
            label: 'Sonora'
        },
        {
            value: 'TB',
            label: 'Tabasco'
        },
        {
            value: 'TL',
            label: 'Tlaxcala'
        },
        {
            value: 'TM',
            label: 'Tamaulipas'
        },
        {
            value: 'VE',
            label: 'Veracruz'
        },
        {
            value: 'YU',
            label: 'Yucatán'
        },
        {
            value: 'ZA',
            label: 'Zacatecas'
        }
    ],
    IE: [
        {
            value: 'CE',
            label: 'Clare'
        },
        {
            value: 'CN',
            label: 'Cavan'
        },
        {
            value: 'CO',
            label: 'Cork'
        },
        {
            value: 'CW',
            label: 'Carlow'
        },
        {
            value: 'D',
            label: 'Dublin'
        },
        {
            value: 'DL',
            label: 'Donegal'
        },
        {
            value: 'G',
            label: 'Galway'
        },
        {
            value: 'KE',
            label: 'Kildare'
        },
        {
            value: 'KK',
            label: 'Kilkenny'
        },
        {
            value: 'KY',
            label: 'Kerry'
        },
        {
            value: 'LD',
            label: 'Longford'
        },
        {
            value: 'LH',
            label: 'Louth'
        },
        {
            value: 'LK',
            label: 'Limerick'
        },
        {
            value: 'LM',
            label: 'Leitrim'
        },
        {
            value: 'LS',
            label: 'Laois'
        },
        {
            value: 'MH',
            label: 'Meath'
        },
        {
            value: 'MN',
            label: 'Monaghan'
        },
        {
            value: 'MO',
            label: 'Mayo'
        },
        {
            value: 'OY',
            label: 'Offaly'
        },
        {
            value: 'RN',
            label: 'Roscommon'
        },
        {
            value: 'SO',
            label: 'Sligo'
        },
        {
            value: 'TA',
            label: 'Tipperary'
        },
        {
            value: 'WD',
            label: 'Waterford'
        },
        {
            value: 'WH',
            label: 'Westmeath'
        },
        {
            value: 'WW',
            label: 'Wicklow'
        },
        {
            value: 'WX',
            label: 'Wexford'
        }
    ]
};

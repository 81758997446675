import { useEffect, useMemo, useState } from 'react';
import type { Money } from '@wilm/shared-types/product/Money';
import { useFormat } from 'helpers/hooks/useFormat';
import useI18n from 'helpers/hooks/useI18n';
import mapCosts from 'helpers/utils/mapCosts';
import { useCart } from 'frontastic';
import type { CostsProps, CostRef } from '../types';

export type UseCostsData = (props: Pick<CostsProps, 'dataReference' | 'order'>) => {
    costsToRender: CostRef[];
    total: CostRef;
    totalAmount: CostRef;
    loading: boolean;
    zeroTaxed: boolean;
};

const useCostsData: UseCostsData = ({ dataReference = 'cart', order }) => {
    const { transaction, taxed } = useCart();
    const { currency } = useI18n();
    const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

    const [loading, setLoading] = useState(true);

    const skeletonMoney: Money = useMemo(() => {
        return { fractionDigits: 2, centAmount: 999, currencyCode: 'USD' };
    }, []);

    const skeletonCosts = useMemo(
        () => [
            {
                key: 'discount',
                label: formatCartMessage({
                    id: 'discount',
                    defaultMessage: 'Discount'
                }),
                value: { fractionDigits: 2, centAmount: 0, currencyCode: 'USD' }
            },
            {
                key: 'subtotal',
                label: formatCartMessage({ id: 'subtotal', defaultMessage: 'Subtotal' }),
                value: skeletonMoney
            },
            {
                key: 'tax',
                label: formatCartMessage({ id: 'tax', defaultMessage: 'Tax' }),
                value: skeletonMoney
            }
        ],
        [formatCartMessage, skeletonMoney]
    ) as CostRef[];

    const costsToRender = useMemo(() => {
        const dataIsHydrated = (dataReference === 'order' && !!order) || (dataReference === 'cart' && !!transaction.total);
        if (!dataIsHydrated) return [];

        const costsToUse = dataReference === 'cart' ? transaction : mapCosts({ order, currency });
        const costs = [...skeletonCosts].map(({ key, label }) => {
            return {
                key,
                label,
                value: costsToUse[key]
            };
        }) as CostRef[];

        return costs.filter(({ key, value }) => key === 'tax' || key === 'subtotal' || !!value?.centAmount);
    }, [currency, dataReference, order, skeletonCosts, transaction]);

    const zeroTaxed = useMemo(() => {
        return dataReference === 'cart' ? taxed?.amount?.centAmount === 0 : order?.taxed?.amount?.centAmount === 0;
    }, [currency, order]);

    const orderTotalRender = useMemo(() => {
        const totalToUse = mapCosts({ order, currency });
        return totalToUse.total;
    }, [currency, order]);

    const total: CostRef = {
        key: 'total',
        label: formatCartMessage({ id: 'total', defaultMessage: 'Total' }),
        value: dataReference === 'cart' ? transaction.total : (orderTotalRender as Money)
    };

    const totalAmount: CostRef = {
        key: 'totalAmount',
        label: formatCartMessage({ id: 'total', defaultMessage: 'Total' }),
        value: transaction.totalAmount
    };

    useEffect(() => {
        if (costsToRender.length) setLoading(false);
    }, [costsToRender, totalAmount]);

    return {
        loading,
        costsToRender: loading
            ? skeletonCosts.filter(({ key, value }) => key === 'tax' || key === 'subtotal' || !!value?.centAmount)
            : costsToRender,
        total,
        totalAmount,
        zeroTaxed
    };
};

export default useCostsData;

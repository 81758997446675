import type { FC } from 'react';
import React from 'react';
import Link from 'components/commercetools-ui/atoms/link';
import type { Reference } from 'types/reference';
import Image from 'frontastic/lib/image';
import type { ImageProps } from 'frontastic/lib/image/types';

export interface Props {
    socialMedia?: SocialMedia[];
}

export interface SocialMedia {
    logo: ImageProps;
    reference?: Reference;
}

const SocialMedia: FC<Props> = ({ socialMedia }) => {
    return (
        <ul className="flex pt-65">
            {socialMedia?.map((media, idx) => (
                <li key={media.logo?.media?.mediaId ?? idx} className={`w-21 md:w-27 ${idx === 0 ? 'mr-9' : 'mx-9'}`}>
                    {media.reference ? (
                        <Link link={media.reference}>
                            <Image {...media.logo} className="custom-filter" />
                        </Link>
                    ) : (
                        <Image {...media.logo} />
                    )}
                </li>
            ))}
        </ul>
    );
};

export default SocialMedia;

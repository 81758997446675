import type { GTMTag, GTMActionPayload, TrackLoginPayloadType } from './../interfaces/gtmTag';
import TagsActionType from './types/tagsActionType';

const loginHandler = ({ payload }: { payload: GTMActionPayload }): GTMTag => {
    const loginPayload = payload as TrackLoginPayloadType;

    return {
        ecommerce: {
            method: loginPayload.method
        },
        event: TagsActionType.LOGIN
    };
};

export default loginHandler;

import type { FC, ReactNode } from 'react';
import React from 'react';
import useClassNames from 'helpers/hooks/useClassNames';

export type WrapperBackground = 'white' | 'neutral-200';

export type WrapperProps = {
    children: ReactNode;
    background?: WrapperBackground;
    className?: string;
    clearDefaultStyles?: boolean;
};

const Wrapper: FC<WrapperProps> = ({ children, background = 'white', className, clearDefaultStyles }) => {
    const wrapperClassName = useClassNames(clearDefaultStyles ? [className] : [className, 'px-16 md:px-24 lg:px-20 xl:px-48']);

    return (
        /* eslint-disable-next-line tailwindcss/no-custom-classname */
        <div className={`bg-${background}`}>
            <div className={wrapperClassName}>{children}</div>
        </div>
    );
};

export default Wrapper;

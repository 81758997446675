import type { FC } from 'react';
import React, { useContext, createContext, useMemo, useState, useCallback } from 'react';
import type { GetLearnerOrderInfoResponse, LearnerResponse } from '@wilm/shared-types/learner/Api';
import type { LearnerOrderInfoType, LearnerSeat, LearnerSettings } from '@wilm/shared-types/learner/Learner';
import { LearnerHashStatusEnum } from '@wilm/shared-types/learner/Learner';
import { initialOpenLearnersFields } from '@wilm/shared-types/validation-rules/learner';
import type { FieldErrors, Fields } from '@wilm/shared-types/validation-rules/types';
import AssignLearnersModal from 'components/learner/organisms/assign-learners-modal';
import FieldsConfirmationModal from 'components/learner/organisms/fields-confirmation-modal';
import { sdk } from 'sdk';

export interface LearnerHashInfo {
    hash: string;
    status: LearnerHashStatusEnum;
    userAgent: string;
    requestUrl: string;
    requestMethod: string;
}
interface LearnerDataProviderProps {
    learnerHashInfo: LearnerHashInfo;
    learnerSettings: LearnerSettings;
    children: React.ReactNode;
}

interface LearnerDataContextShape {
    learnerOrderInfo: LearnerOrderInfoType | undefined;
    learnerSettings: LearnerSettings;
    learnerOrderInfoLoading: boolean;
    handleTemplateGeneration: () => void;
    openAssigningModal: (lineItemId: string) => void;
    assignLearners: (
        lineItemId: string,
        learnerSeats: LearnerSeat[]
    ) => Promise<{ isError: boolean; errorMessage?: string; fieldErrors?: Record<string, FieldErrors> }>;
}

const LearnerDataContext = createContext<LearnerDataContextShape>({} as LearnerDataContextShape);

const LearnerDataProvider: FC<LearnerDataProviderProps> = ({ learnerSettings, learnerHashInfo, children }) => {
    const [openLearnersFields, setOpenLearnersFields] = useState<Fields>(initialOpenLearnersFields);
    const [selectedLineItemId, setSelectedLineItemId] = useState<string>('');
    const [isAssigningModalOpen, setIsAssigningModalOpen] = useState<boolean>(false);
    const [learnerOrderInfo, setLearnerOrderInfo] = useState<LearnerOrderInfoType | undefined>(undefined);
    const [learnerOrderInfoLoading, setLearnerOrderInfoLoading] = useState<boolean>(false);

    const isOrderNumberConfirmationModalOpen = useMemo(
        () => !learnerOrderInfo && learnerHashInfo.status === LearnerHashStatusEnum.OK,
        [learnerOrderInfo, learnerHashInfo.status]
    );

    const getLearnerOrderInfo = useCallback(
        async (
            openLearnersFields: Fields
        ): Promise<{ isError: true; errorMessage: string; fieldErrors: FieldErrors } | { isError: false }> => {
            setLearnerOrderInfoLoading(true);
            const payload = {
                orderNumber: openLearnersFields.orderNumber.value,
                hash: learnerHashInfo.hash,
                userAgent: learnerHashInfo.userAgent,
                requestUrl: learnerHashInfo.requestUrl,
                requestMethod: learnerHashInfo.requestMethod
            };

            const learnerOrderInfoResults = await sdk.callAction<LearnerResponse<LearnerOrderInfoType>>({
                actionName: 'learner/getLearnerOrderInfo',
                payload
            });
            setLearnerOrderInfoLoading(false);

            if (learnerOrderInfoResults.isError) {
                return {
                    isError: true,
                    errorMessage:
                        'Error fetching learner order info. Please try again. If the problem persists, contact support with the following error: ' +
                        JSON.stringify(learnerOrderInfoResults.error.message),
                    fieldErrors: {} as FieldErrors
                };
            }

            if (learnerOrderInfoResults.data.isError) {
                return { isError: true, errorMessage: learnerOrderInfoResults.data.errors[0].message, fieldErrors: {} as FieldErrors };
            }

            setLearnerOrderInfo(learnerOrderInfoResults.data.data);
            return { isError: false };
        },
        [setLearnerOrderInfo, setLearnerOrderInfoLoading]
    );

    const assignLearners = useCallback(
        async (lineItemId: string, learnerSeats: LearnerSeat[]) => {
            const payload = {
                hash: learnerHashInfo.hash,
                lineItemId,
                unlockId: learnerOrderInfo?.unlockId,
                learnerSeats,
                userAgent: learnerHashInfo.userAgent,
                requestUrl: learnerHashInfo.requestUrl,
                requestMethod: learnerHashInfo.requestMethod
            };

            const assignLearnersResults = await sdk.callAction<GetLearnerOrderInfoResponse>({
                actionName: 'learner/assignLearners',
                payload
            });

            console.info('---> assignLearnersResults', assignLearnersResults);

            if (assignLearnersResults.isError) {
                return {
                    isError: true,
                    errorMessage:
                        'Error assigning learners. Please try again. If the problem persists, contact support with the following error: ' +
                        JSON.stringify(assignLearnersResults.error.message)
                };
            }

            if (assignLearnersResults.data.error) {
                return {
                    isError: true,
                    errorMessage: assignLearnersResults.data.error.message,
                    fieldErrors: assignLearnersResults.data.error.fieldErrors ?? ({} as Record<string, FieldErrors>)
                };
            }

            setLearnerOrderInfo(assignLearnersResults.data.data);

            return { isError: false };
        },
        [learnerHashInfo, learnerOrderInfo?.unlockId, setLearnerOrderInfo]
    );

    const handleTemplateGeneration = useCallback(() => {
        const csvContent = 'firstName,lastName,email\n';

        const blob = new Blob([csvContent], { type: 'text/csv' });
        const link = document.createElement('a');

        link.download = 'learners.csv';
        link.href = window.URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
    }, []);

    const openAssigningModal = useCallback((lineItemId: string) => {
        setSelectedLineItemId(lineItemId);
        setIsAssigningModalOpen(true);
    }, []);

    const value = useMemo(
        () => ({
            learnerOrderInfo,
            learnerSettings,
            learnerOrderInfoLoading,
            handleTemplateGeneration,
            openAssigningModal,
            assignLearners
        }),
        [learnerOrderInfo, learnerSettings, learnerOrderInfoLoading, handleTemplateGeneration, openAssigningModal, assignLearners]
    );

    return (
        <LearnerDataContext.Provider value={value}>
            <FieldsConfirmationModal
                isOpen={isOrderNumberConfirmationModalOpen}
                fields={openLearnersFields}
                formName="openLearnersFields"
                validateOnBlur={false}
                modalTitle=""
                modalDescription={learnerSettings.confirmOrderNumberModalDesctiption}
                showCloseButton={false}
                showSubmitButton={true}
                submitButtonText="Confirm"
                handleFieldChange={(field, value) => {
                    setOpenLearnersFields({
                        ...openLearnersFields,
                        [field.name]: {
                            ...field,
                            value
                        }
                    });
                }}
                onClose={() => {}}
                onSubmit={getLearnerOrderInfo}
            />
            {learnerOrderInfo && (
                <AssignLearnersModal
                    isOpen={isAssigningModalOpen}
                    close={() => setIsAssigningModalOpen(false)}
                    selectedLineItem={learnerOrderInfo?.order.lineItems.find(lineItem => lineItem.lineItemId === selectedLineItemId)}
                />
            )}
            {children}
        </LearnerDataContext.Provider>
    );
};

export default LearnerDataProvider;

export const useLearnerDataContext = () => useContext(LearnerDataContext);

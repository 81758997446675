import type { Variant } from '@wilm/shared-types/product';

/**
 * Sorts variants by start date, if the start date is not available, the variant will be placed at the end of the list
 *
 * @param variants Array of variants to sort
 * @param order Sorting order (asc or desc)
 * @returns Array of sorted variants
 */
export const sortVariantsByStartDate = (variants: Variant[], order: 'asc' | 'desc' = 'asc'): Variant[] => {
    let variantsToSort = structuredClone(variants);
    variantsToSort = variantsToSort.sort((a, b) => {
        if (order === 'desc') {
            return new Date(b.attributes?.startDate ?? 0).getTime() - new Date(a.attributes?.startDate ?? 0).getTime();
        }
        return new Date(a.attributes?.startDate ?? 0).getTime() - new Date(b.attributes?.startDate ?? 0).getTime();
    });

    return variantsToSort;
};

import React from 'react';
import Typography from 'components/commercetools-ui/atoms/typography';
import useClassNames from 'helpers/hooks/useClassNames';
import Image from 'frontastic/lib/image';
import type { ImageProps } from 'frontastic/lib/image/types';

type Props = {
    image: ImageProps;
    title: string;
    className?: string;
};

export const EmptyState: React.FC<Props> = ({ image, title, className = '' }: Props) => {
    const titleClassNames = useClassNames(['py-32', className]);

    return (
        <div className={titleClassNames}>
            <Typography as="h2" className="text-center text-md">
                {title}
            </Typography>
            <div className="flex w-full justify-center">
                <div className="relative mt-20 h-138 w-202 px-10 text-center">
                    <Image media={image?.media} className="w-full" alt={image?.title} />
                </div>
            </div>
        </div>
    );
};

import type { FC } from 'react';
import React, { useState } from 'react';
import type { LineItem } from '@wilm/shared-types/cart/LineItem';
import Accordion from 'components/commercetools-ui/atoms/accordion';
import useClassNames from 'helpers/hooks/useClassNames';
import { useFormat } from 'helpers/hooks/useFormat';
import useBundle from 'frontastic/hooks/useBundle';
import OrderItemsList from './order-items-list';

type OrderItemsListingProps = {
    className?: string;
    lineItems: LineItem[];
    lineItemsExpanded?: boolean;
    isThanksPage?: boolean;
};

const OrderItemsListing: FC<OrderItemsListingProps> = ({ className, lineItems, lineItemsExpanded, isThanksPage }) => {
    const { formatMessage: formatOrdersMessage } = useFormat({ name: 'orders' });

    const [open, setOpen] = useState(!!lineItemsExpanded);

    const accordionClassNames = useClassNames(['hidden lg:block lg:pt-0', className]);
    const listClassNames = useClassNames(['block lg:hidden', className]);

    const { totalItems } = useBundle(lineItems);
    const itemsLabel =
        totalItems === 1
            ? formatOrdersMessage({
                  id: 'item',
                  values: { total: totalItems },
                  defaultMessage: '{total} item'
              })
            : formatOrdersMessage({
                  id: 'items',
                  values: { total: totalItems },
                  defaultMessage: '{total} items'
              });
    const sectionTitle = !isThanksPage
        ? itemsLabel
        : formatOrdersMessage({
              id: 'your.order',
              defaultMessage: 'Your Order'
          });

    return (
        <>
            <Accordion
                closedSectionTitle={sectionTitle}
                className={accordionClassNames}
                iconClassName="w-24"
                buttonClassName="py-16 w-full font-bold"
                onClick={() => setOpen(!open)}
            >
                <OrderItemsList lineItems={lineItems} />
            </Accordion>
            <OrderItemsList className={listClassNames} lineItems={lineItems} />
        </>
    );
};

export default OrderItemsListing;

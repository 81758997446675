import React from 'react';
import SpinnerIcon from 'components/icons/spinner';

interface Props {
    className?: string;
    hasLoader?: boolean;
    onClick?: () => void;
}

const Overlay: React.FC<Props> = ({ className, onClick, hasLoader = false }) => {
    const customClass = className
        ? `fixed left-0 top-0 h-screen w-screen ${className}`
        : 'fixed left-0 top-0 z-[250] h-screen w-screen bg-[#7f7f7f]/30';
    return (
        <button className={customClass} onClick={onClick}>
            {hasLoader && <SpinnerIcon className="absolute left-[calc(50vw-30px)] top-1/2 size-40 animate-spin  text-white" />}
        </button>
    );
};

export default Overlay;

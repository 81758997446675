import type { FC, ReactNode } from 'react';
import React from 'react';

interface CartItemContainerType {
    children: ReactNode;
    isAutoAdded?: boolean;
}

const CartItemContainer: FC<CartItemContainerType> = ({ children, isAutoAdded }) => {
    return (
        <div className="flex max-w-full items-stretch justify-start gap-10 py-18 md:gap-15" data-cy={`${isAutoAdded ? '' : 'cart-item'}`}>
            <div className="max-w-full grow overflow-hidden">{children}</div>
        </div>
    );
};

export default CartItemContainer;

import type { FC } from 'react';
import React, { useContext, createContext, useMemo, useCallback, useState } from 'react';
import type { SDKResponse } from '@commercetools/frontend-sdk';
import type { CybersourceIframesProps } from '@wilm/shared-frontend/components/cybersource/iframes';
import type { Order } from '@wilm/shared-types/cart';
import type { PaymentObject } from '@wilm/shared-types/cart/Payment';
import type {
    StudioProjectSettingsForPayments,
    CreateTokenCallback,
    CreateTokenError,
    DeviceDataParams,
    Flex
} from '@wilm/shared-types/cybersource';
import { CybersourceGeneralError } from '@wilm/shared-types/cybersource';
import type { PaymentLinkInfoType } from '@wilm/shared-types/payment-link/PaymentLink';
import { FieldsValidationError, type FieldErrors } from '@wilm/shared-types/validation-rules/types';
import CybersourceIframes from 'components/cybersource/iframes';
import { sdk } from 'sdk';

interface CybersourceProviderProps {
    cybersourceSettings: StudioProjectSettingsForPayments;
    children: React.ReactNode;
}
interface CybersourceContextShape {
    makeCCPayment: (options: MakeCCPaymentOptions, paymentLinkInfo: PaymentLinkInfoType) => Promise<MakeCCPaymentReturn>;
    setupMicroform: (signature: string) => void;
}

type MakeCCPaymentOptions = {
    expirationMonth: string;
    expirationYear: string;
};

interface MakeCCPaymentReturn {
    isError: boolean;
    needsToStartFromBeginning: boolean;
    fieldsErrors: FieldErrors;
    errorMessage?: string;
    order?: Order;
}

const CybersourceContext = createContext<CybersourceContextShape>({} as CybersourceContextShape);

const CybersourceProvider: FC<CybersourceProviderProps> = ({ cybersourceSettings, children }) => {
    const [microform, setMicroform] = useState<Flex['microform'] | null>(null);
    const [iframesData, setIframesData] = useState<CybersourceIframesProps>({});

    const setupMicroform = useCallback((signature: string) => {
        const myStyles = {
            input: {
                'font-size': '14px',
                'font-family': 'helvetica, tahoma, calibri, sans-serif',
                color: '#555'
            },
            ':focus': { color: 'blue' },
            ':disabled': { cursor: 'not-allowed' },
            '::placeholder': {
                color: '#414141'
            },
            valid: { color: '#3c763d' },
            invalid: { color: '#a94442' }
        };

        const flex = new window.Flex(signature);

        const microform = flex.microform({ styles: myStyles });
        const number = microform.createField('number', { placeholder: 'Card Number', id: 'cs-form-number-container' });
        const securityCode = microform.createField('securityCode', { placeholder: '***' });

        number.load('#number-container');
        securityCode.load('#securityCode-container');
        setMicroform(microform);
    }, []);

    const createTransientToken = useCallback(
        (options: { expirationMonth: string; expirationYear: string }): Promise<{ error: CreateTokenError; token: string }> => {
            return new Promise(resolve => {
                const microformCreateTokenCallback: CreateTokenCallback = (error, token) => {
                    resolve({ error, token });
                };
                console.log('---> call microform?.createToken');

                microform?.createToken(options, microformCreateTokenCallback);
            });
        },
        [microform]
    );

    const getTransientToken = useCallback(
        async (options: { expirationMonth: string; expirationYear: string }): Promise<string> => {
            const result = await createTransientToken(options);

            const { error, token } = result;
            console.log('---> result', result);

            if (error) {
                console.log('---> error', error);

                switch (error.reason) {
                    case 'CREATE_TOKEN_VALIDATION_PARAMS':
                    case 'CREATE_TOKEN_VALIDATION_FIELDS':
                    case 'CREATE_TOKEN_VALIDATION_SERVERSIDE':
                        if (error?.details && Array.isArray(error.details) && error.details.length > 0) {
                            const errors: string[] = [];
                            error.details.forEach(errorDetail => {
                                console.log('---> errorDetail', errorDetail);
                                errors.push(errorDetail.location);
                            });

                            const createTokenFieldErrors = handleMicroformCreateTokenErrors(errors);

                            throw new FieldsValidationError({ errors: createTokenFieldErrors });
                        } else {
                            //reset form and start from the beginning
                            console.error('---> ERROR ' + error.reason + ' need to reset form and start from the beginning');
                            throw new CybersourceGeneralError();
                        }
                    default:
                        //reset form and start from the beginning
                        console.error('---> ERROR ' + error.reason + ' need to reset form and start from the beginning');
                        throw new CybersourceGeneralError();
                }
            } else {
                if (!token) {
                    //reset form and start from the beginning
                    console.error('---> ERROR Token is missing need to reset form and start from the beginning');
                    throw new CybersourceGeneralError();
                }
                return token;
            }
        },
        [createTransientToken]
    );

    const handleMicroformCreateTokenErrors = (errors: string[]) => {
        const fieldsErrors: FieldErrors = {};
        errors.forEach(error => {
            switch (error) {
                case 'number':
                    fieldsErrors.cardNumber = {
                        showError: true,
                        message: 'error.validation.cardNumber'
                    };
                    break;
                case 'securityCode':
                    fieldsErrors.securityCode = {
                        showError: true,
                        message: 'error.validation.securityCode'
                    };
                    break;
                case 'expirationMonth':
                    fieldsErrors.expiryMonth = {
                        showError: true,
                        message: 'error.validation.expiryMonth'
                    };
                    break;
                case 'expirationYear':
                    fieldsErrors.expiryYear = {
                        showError: true,
                        message: 'error.validation.expiryYear'
                    };
                    break;
                default:
                    break;
            }
        });
        return fieldsErrors;
    };

    const updatePaymentWithTransientToken = useCallback(
        async (token: string, paymentLinkInfo: PaymentLinkInfoType): Promise<PaymentObject> => {
            const response = await sdk.callAction({
                actionName: `salesLink/updateOrderPaymentWithTransientToken`,
                payload: {
                    token,
                    hash: paymentLinkInfo.hash,
                    unlockId: paymentLinkInfo.unlockId
                }
            });
            const payment = (response.isError ? null : response.data) as PaymentObject;

            return payment;
        },
        []
    );

    const updatePaymentWithClientUserData = useCallback(
        async (acceptHeader: string, userAgentHeader: string, paymentLinkInfo: PaymentLinkInfoType): Promise<PaymentObject | null> => {
            const response: SDKResponse<PaymentObject> = await sdk.callAction({
                actionName: `salesLink/updatePaymentWithClientUserData`,
                payload: {
                    acceptHeader,
                    userAgentHeader,
                    hash: paymentLinkInfo.hash,
                    unlockId: paymentLinkInfo.unlockId
                }
            });
            const payment = response.isError ? null : response.data;

            return payment;
        },
        []
    );

    const updatePaymentWithThreeDsTransactionId = useCallback(
        async (
            transactionId: string,
            paymentLinkInfo: PaymentLinkInfoType
        ): Promise<{ payment?: PaymentObject; error: boolean; errorMsg?: string }> => {
            const response: SDKResponse<{ payment?: PaymentObject; error: boolean; errorMsg?: string }> = await sdk.callAction({
                actionName: `salesLink/updatePaymentWithThreeDsTransactionId`,
                payload: {
                    transactionId,
                    hash: paymentLinkInfo.hash,
                    unlockId: paymentLinkInfo.unlockId
                }
            });

            if (!response.isError && response.data) {
                return response.data;
            }

            return {
                payment: undefined,
                error: true,
                errorMsg: 'Update payment with 3DS transaction ID failed'
            };
        },
        []
    );

    const waitForNextMessage = (predicate: (event: MessageEvent) => boolean, timeout: number) => {
        return new Promise((resolve, reject) => {
            function messageHandler(event: MessageEvent) {
                // Ensure the message is from the expected origin
                console.log('---> messageHandler', event);

                if (predicate(event)) {
                    resolve(event.data); // Resolve the promise with the message data
                    window.removeEventListener('message', messageHandler); // Cleanup
                }
            }

            window.addEventListener('message', messageHandler, false);

            // Optional: Set a timeout to reject the promise if the message doesn't arrive
            setTimeout(() => {
                reject(new Error('Timeout waiting for message'));
                window.removeEventListener('message', messageHandler); // Cleanup
            }, timeout);
        });
    };

    const addPaymentTransaction = useCallback(async (paymentLinkInfo: PaymentLinkInfoType): Promise<PaymentObject> => {
        const paymentResponse = await sdk.callAction<{ payment: PaymentObject }>({
            actionName: 'salesLink/addPaymentTransaction',
            payload: {
                hash: paymentLinkInfo.hash,
                unlockId: paymentLinkInfo.unlockId
            }
        });

        if (paymentResponse.isError || !paymentResponse.data?.payment) {
            throw new CybersourceGeneralError();
        }

        return paymentResponse.data.payment;
    }, []);

    const updateOrderStatusAsPaidAndCloseLinkIfHasSucessTransaction = useCallback(
        async (paymentLinkInfo: PaymentLinkInfoType): Promise<Order> => {
            const paymentResponse = await sdk.callAction<{ order: Order }>({
                actionName: 'salesLink/updateOrderStatusAsPaidAndCloseLinkIfHasSucessTransaction',
                payload: {
                    hash: paymentLinkInfo.hash,
                    unlockId: paymentLinkInfo.unlockId
                }
            });

            if (paymentResponse.isError || !paymentResponse.data?.order) {
                throw new CybersourceGeneralError();
            }

            return paymentResponse.data.order;
        },
        []
    );

    const dataCollectionHandler = useCallback(
        async (deviceData: DeviceDataParams, paymentLinkInfo: PaymentLinkInfoType): Promise<PaymentObject | null> => {
            // step 6 submit device data
            const cardinalCollectionForm = document.querySelector<HTMLFormElement>('#cardinal_collection_form');

            console.log('---> cardinalCollectionForm', cardinalCollectionForm);

            if (!cardinalCollectionForm) {
                console.error('---> Cardinal form is missing');
                throw new CybersourceGeneralError();
            }
            cardinalCollectionForm.action = deviceData.isv_deviceDataCollectionUrl!;
            cardinalCollectionForm.querySelector<HTMLInputElement>('#cardinal_collection_form_input')!.value = deviceData.isv_requestJwt!;
            cardinalCollectionForm.submit();

            // step 7 Wait for the successful response from the deviceDataCollection
            try {
                console.log('---> call waitForNextMessage');

                const response = await waitForNextMessage(
                    (event: MessageEvent) => event.origin === cybersourceSettings.cardinalOrigin,
                    20000
                );

                console.log('---> response', response);
            } catch (error) {
                console.error('---> Error', error);
                throw new CybersourceGeneralError();
            }

            // step 7 initialize the enrollment check by updating the payment

            const acceptHeader = '*/*';
            const userAgentHeader = window.navigator.userAgent;

            const paymentWithClientUserData = await updatePaymentWithClientUserData(acceptHeader, userAgentHeader, paymentLinkInfo);

            console.log('---> paymentWithClientUserData', paymentWithClientUserData);

            if (
                // step 9 check this and repeat from step 6
                paymentWithClientUserData?.payerEnrollmentData?.isv_payerEnrollHttpCode === 201 &&
                paymentWithClientUserData?.payerEnrollmentData?.isv_payerEnrollStatus === 'CUSTOMER_AUTHENTICATION_REQUIRED'
            ) {
                console.log('repeat from step 6');
                return await dataCollectionHandler(deviceData, paymentLinkInfo);
            }

            return paymentWithClientUserData;
        },
        [updatePaymentWithClientUserData, cybersourceSettings.cardinalOrigin]
    );

    const processPaymentEnrollmentData = async (
        payment: PaymentObject,
        paymentLinkInfo: PaymentLinkInfoType
    ): Promise<MakeCCPaymentReturn> => {
        const paymentEnrollmentData = payment.payerEnrollmentData;
        if (paymentEnrollmentData.isv_payerEnrollHttpCode === 201 && paymentEnrollmentData.isv_payerEnrollStatus === 'AUTHORIZED') {
            console.log('Direct Authorized NO Step up');
            //successful payment
            return {
                isError: false,
                needsToStartFromBeginning: false,
                fieldsErrors: {},
                errorMessage: ''
            };
        }
        if (paymentEnrollmentData.isv_payerEnrollHttpCode === 201 && paymentEnrollmentData.isv_payerEnrollStatus === 'DECLINED') {
            console.error('card declined');
            return {
                isError: true,
                needsToStartFromBeginning: true,
                fieldsErrors: {},
                errorMessage: 'Your card was declined please try again or use another card'
            };
        }
        if (paymentEnrollmentData.isv_payerEnrollHttpCode === 400) {
            console.error('Payment can not be processed');
            return {
                isError: true,
                needsToStartFromBeginning: true,
                fieldsErrors: {},
                errorMessage: 'Payment can not be processed'
            };
        }
        if (paymentEnrollmentData.isv_payerAuthenticationRequired === true) {
            return await handlePayerAuthentication(paymentLinkInfo, paymentEnrollmentData);
        }
        return {
            isError: true,
            needsToStartFromBeginning: true,
            fieldsErrors: {},
            errorMessage: 'Could not process payment enrollment data'
        };
    };

    const handlePayerAuthentication = async (
        paymentLinkInfo: PaymentLinkInfoType,
        paymentEnrollmentData: PaymentObject['payerEnrollmentData']
    ) => {
        console.log('Need step up');

        setIframesData(prevState => ({
            ...prevState,
            showSubmitStepUpIframe: true,
            stepUpUrl: paymentEnrollmentData.isv_stepUpUrl,
            responseJwt: paymentEnrollmentData.isv_responseJwt
        }));

        console.log('---> iframesData', iframesData);

        const stepUpForm = document.querySelector<HTMLFormElement>('#step-up-form');
        console.log('---> stepUpForm', stepUpForm);

        if (!stepUpForm) {
            console.error('Step up form is missing');
            return {
                isError: true,
                needsToStartFromBeginning: true,
                fieldsErrors: {},
                errorMessage: 'Step up form is missing'
            };
        }

        stepUpForm.action = paymentEnrollmentData.isv_stepUpUrl!;
        stepUpForm.querySelector<HTMLInputElement>('#step-up-form-input')!.value = paymentEnrollmentData.isv_responseJwt!;

        stepUpForm.submit();

        let response = '';
        try {
            console.log('---> call waitForNextMessage');
            response = (await waitForNextMessage(
                (event: MessageEvent) => event.origin === window.location.origin && typeof event.data === 'string',
                15 * 60 * 1000 // timeout 15 min
            )) as string;

            console.log('---> response', response);
        } catch (error) {
            console.error('---> Error', error);
            return {
                isError: true,
                needsToStartFromBeginning: true,
                fieldsErrors: {},
                errorMessage: 'Could not process payment enrollment data'
            };
        }

        const pspResponse = JSON.parse(response);

        if (!pspResponse?.transactionId) {
            console.error('Transaction ID is missing');
            return {
                isError: true,
                needsToStartFromBeginning: true,
                fieldsErrors: {},
                errorMessage: 'Transaction ID is missing'
            };
        }

        const paymentUpdate = await updatePaymentWithThreeDsTransactionId(pspResponse.transactionId, paymentLinkInfo);

        console.log('---> paymentUpdate', paymentUpdate);

        if (paymentUpdate.error && paymentUpdate.errorMsg === 'DuplicatedPayments') {
            console.error('Payment for this basket has already been processed. Please contact support for further assistance.');
            return {
                isError: true,
                needsToStartFromBeginning: true,
                fieldsErrors: {},
                errorMessage: 'Payment for this basket has already been processed. Please contact support for further assistance.'
            };
        }

        if (paymentUpdate.error || !paymentUpdate.payment) {
            console.error('Could not process payment enrollment data');
            return {
                isError: true,
                needsToStartFromBeginning: true,
                fieldsErrors: {},
                errorMessage: 'Could not process payment enrollment data'
            };
        }

        setIframesData(prevState => ({
            ...prevState,
            showSubmitStepUpIframe: false
        }));

        return await processPaymentEnrollmentData(paymentUpdate.payment, paymentLinkInfo);
    };

    const makeCCPayment = useCallback(
        async (options: MakeCCPaymentOptions, paymentLinkInfo: PaymentLinkInfoType) => {
            console.log('---> makeCCPayment', options);

            let token = '';
            try {
                token = await getTransientToken(options);
            } catch (error) {
                if (error instanceof FieldsValidationError) {
                    return {
                        isError: true,
                        needsToStartFromBeginning: false,
                        fieldsErrors: error.errors
                    };
                } else {
                    return {
                        isError: true,
                        needsToStartFromBeginning: true,
                        fieldsErrors: {}
                    };
                }
            }

            const payment = await updatePaymentWithTransientToken(token, paymentLinkInfo);

            console.log('---> payment', payment);

            if (
                !payment?.paymentMethod ||
                !payment?.deviceDataParams?.isv_requestJwt ||
                !payment?.deviceDataParams?.isv_deviceDataCollectionUrl ||
                !payment?.deviceDataParams?.isv_cardinalReferenceId
            ) {
                console.log('---> Missing payment or Device data request parameters not received');
                // show error
                return {
                    isError: true,
                    needsToStartFromBeginning: true,
                    fieldsErrors: {}
                };
            }

            const deviceData = payment.deviceDataParams as DeviceDataParams;

            setIframesData(prevState => ({
                ...prevState,
                deviceDataCollectionUrl: deviceData.isv_deviceDataCollectionUrl,
                requestJwt: deviceData.isv_requestJwt
            }));

            let paymentWithClientUserData;
            try {
                paymentWithClientUserData = await dataCollectionHandler(deviceData, paymentLinkInfo);
                console.log('---> paymentWithClientUserData', paymentWithClientUserData);
            } catch (error) {
                return {
                    isError: true,
                    needsToStartFromBeginning: true,
                    fieldsErrors: {}
                };
            }

            if (!paymentWithClientUserData) {
                return {
                    isError: true,
                    needsToStartFromBeginning: true,
                    fieldsErrors: {}
                };
            }

            const processedEnrolmentData = await processPaymentEnrollmentData(paymentWithClientUserData, paymentLinkInfo);
            console.log('---> processedEnrolmentData', processedEnrolmentData);

            if (processedEnrolmentData.isError) {
                return {
                    isError: true,
                    needsToStartFromBeginning: processedEnrolmentData.needsToStartFromBeginning,
                    fieldsErrors: {}
                };
            }

            let paymentAfterTransaction;
            try {
                paymentAfterTransaction = await addPaymentTransaction(paymentLinkInfo);
                console.log('---> paymentAfterTransaction', paymentAfterTransaction);
            } catch (error) {
                return {
                    isError: true,
                    needsToStartFromBeginning: true,
                    fieldsErrors: {}
                };
            }

            if (paymentAfterTransaction.transactionStatus !== 'Success') {
                return {
                    isError: true,
                    needsToStartFromBeginning: true,
                    fieldsErrors: {}
                };
            }

            const order = await updateOrderStatusAsPaidAndCloseLinkIfHasSucessTransaction(paymentLinkInfo);

            console.log('---> order', order);

            return {
                isError: false,
                needsToStartFromBeginning: false,
                fieldsErrors: {},
                order
            };
        },
        [getTransientToken, updatePaymentWithTransientToken, addPaymentTransaction, dataCollectionHandler]
    );

    const value = useMemo(
        () => ({
            makeCCPayment,
            setupMicroform
        }),
        [makeCCPayment, setupMicroform]
    );

    return (
        <CybersourceContext.Provider value={value}>
            <CybersourceIframes
                showSubmitStepUpIframe={iframesData?.showSubmitStepUpIframe}
                deviceDataCollectionUrl={iframesData?.deviceDataCollectionUrl}
                requestJwt={iframesData?.requestJwt}
                stepUpUrl={iframesData?.stepUpUrl}
                responseJwt={iframesData?.responseJwt}
            />
            {children}
        </CybersourceContext.Provider>
    );
};

export default CybersourceProvider;

export const useCybersourceContext = () => useContext(CybersourceContext);

import type { ComposableCommerceEvents } from '@commercetools/frontend-composable-commerce';
import { ComposableCommerce } from '@commercetools/frontend-composable-commerce';
import { SDK } from '@commercetools/frontend-sdk';
import { getLocalizationInfo } from 'project.config';
// Add other extension's custom events to the SDK's generic type here,
// by extending ComposableCommerceEvents with their type with an
// intersection. For example <ComposableCommerceEvents & OtherEvents>.
class CommercetoolsSDK extends SDK<ComposableCommerceEvents> {
    composableCommerce!: ComposableCommerce;
    // Add your other extensions here.
    constructor() {
        super();
        this.composableCommerce = new ComposableCommerce(this);
        // Initialise your other extensions here.
        this.on('errorCaught', event => {
            // Globally handle any errors caught by the SDK from your
            // extensions. Log error, fire notification etc...
            console.log('SDK error: ', event.data);
        });
    }

    getConfig(nextJsLocale: string) {
        const { locale, currency } = getLocalizationInfo(nextJsLocale);
        if (!process.env.NEXT_PUBLIC_FRONTASTIC_HOST) {
            throw new Error('missing required configuration: NEXT_PUBLIC_FRONTASTIC_HOST');
        }

        const config = {
            locale,
            currency,
            extensionVersion: process.env.NEXT_PUBLIC_EXT_BUILD_ID ?? 'dev',
            endpoint: process.env.NEXT_PUBLIC_FRONTASTIC_HOST.split('/frontastic')[0]
        };

        return config;
    }

    configureForNext(nextJsLocale: string) {
        const config = this.getConfig(nextJsLocale);
        sdk.configure(config);
    }
}
// Create a single instance of the sdk.
const sdk = new CommercetoolsSDK();
// Export only the instance to serve as a singleton throughout
// the project.
export { sdk };

import type { LineItem } from '@wilm/shared-types/cart/LineItem';
import { ProductTypeKey } from '@wilm/shared-types/product/Product';
import { formatDate } from 'helpers/dateHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import type { UseLineItemReturn } from './types';
import { useBrandSettingsContext } from 'providers/brand-settings';

const useLineItem = (lineItem: LineItem): UseLineItemReturn => {
    const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
    const productTypeKey = lineItem?.productTypeKey ?? '';

    let lineItemData = null;
    const { showLearningMode, showStartDate } = useBrandSettingsContext();

    if (productTypeKey === ProductTypeKey.TRAINING) {
        const attributes = lineItem?.variant?.attributes;
        const learningMode: string = attributes?.learningMode?.label || '';
        const isSelfPlaced: boolean = attributes?.learningMode?.key === 'selfPaced';
        const startDate: string = attributes?.startDate || '';
        const formattedItemStartDate: string = startDate ? formatDate(startDate) : '';
        const location: string = attributes?.location || '';

        lineItemData = {
            learningModeWithLabel: {
                label: formatCartMessage({ id: 'learning.mode', defaultMessage: 'Learning mode' }),
                value: learningMode,
                hidden: !learningMode?.length || !showLearningMode
            },
            startDateWithLabel: {
                label: formatCartMessage({ id: 'start.date', defaultMessage: 'Start Date' }),
                value: formattedItemStartDate,
                hidden: !formattedItemStartDate?.length || isSelfPlaced || !showStartDate
            },
            locationWithLabel: {
                label: formatCartMessage({ id: 'location', defaultMessage: 'Location' }),
                value: location,
                hidden: !location?.length
            }
        };
    } else if (productTypeKey === ProductTypeKey.MEMBERSHIP) {
        const duration: number = lineItem?.variant?.attributes ? lineItem.variant.attributes.duration : 0;
        lineItemData = {
            durationWithLabel: {
                label: formatCartMessage({ id: 'duration', defaultMessage: 'Duration' }),
                value: duration,
                hidden: !duration
            }
        };
    } else if (productTypeKey === ProductTypeKey.BUNDLE) {
        const duration: number = lineItem?.variant?.attributes?.duration ?? 0;
        lineItemData = {
            durationWithLabel: {
                label: formatCartMessage({ id: 'duration', defaultMessage: 'Duration' }),
                value: duration,
                hidden: !duration
            },
            selectedCommencementDateWithLabel: {
                label: formatCartMessage({ id: 'commencement.date', defaultMessage: 'Preferred Commencement Date' }),
                value: lineItem.selectedCommencementDate ? formatDate(lineItem.selectedCommencementDate) : '',
                hidden: !lineItem.selectedCommencementDate
            }
        };
    }

    return { lineItemData };
};

export default useLineItem;

import type { RefObject } from 'react';
import { useEffect } from 'react';

function useOnClickOutside<T extends HTMLElement = HTMLElement>(ref: RefObject<T>, handler: () => void, enabled = true) {
    useEffect(() => {
        if (!enabled) return;

        const handleMouseDown = (event: MouseEvent | TouchEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                handler();
            }
        };

        function handleKeyUp(event: KeyboardEvent) {
            if (event.key === 'Escape') {
                handler();
            }
        }

        document.addEventListener('mouseup', handleMouseDown);
        document.addEventListener('keyup', handleKeyUp);

        return () => {
            document.removeEventListener('mouseup', handleMouseDown);
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, [ref, handler, enabled]);
}

export default useOnClickOutside;

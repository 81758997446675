import React from 'react';
import CartIcon from 'components/icons/cart-icon';

type Props = {
    className?: string;
    totalCartItems?: number;
    counterClassName?: string;
    cartLabel?: string;
    iconClassName?: string;
};

const Icon: React.FC<Props> = ({ className, totalCartItems = 0, counterClassName, cartLabel, iconClassName }) => (
    <div className={className}>
        {totalCartItems > 0 && <span className={counterClassName} />}
        <p className="flex h-header-icons items-center md:h-header-icons-md">
            <CartIcon className={iconClassName} />
        </p>
        {cartLabel && <p className="mt-5 text-icons font-icons uppercase leading-tight md:text-icons-md">{cartLabel}</p>}
    </div>
);

export default Icon;

import type { Currency } from '@commercetools/frontend-sdk/lib/types/Currency';
import type { GTMTag, GTMActionPayload, TrackRemoveFromCartPayloadType } from './../interfaces/gtmTag';
import TagsActionType from './types/tagsActionType';
import getDiscountCodesString from '../utils/getDiscountCodesString';
import getGtmItems from '../utils/getGtmItems';
import getGtmValue from '../utils/getGtmValue';

const removeFromCartHandler = ({ payload }: { payload: GTMActionPayload }): GTMTag => {
    const removeFromCartPayload = payload as TrackRemoveFromCartPayloadType;
    const removeFromCartItem = { ...removeFromCartPayload.item, count: removeFromCartPayload.quantity };
    const removeFromCartDiscountCodes = getDiscountCodesString(removeFromCartItem?.discounts ?? []);
    const removeFromCartItems = getGtmItems([removeFromCartItem]);
    const removeFromCartValue = getGtmValue(
        removeFromCartItem.discountedPrice! || removeFromCartItem.price!,
        removeFromCartPayload.quantity
    );
    const removeFromCartCurrency = removeFromCartItem?.price?.currencyCode as Currency;

    return {
        ecommerce: {
            currency: removeFromCartCurrency,
            items: removeFromCartItems,
            value: removeFromCartValue,
            ...(removeFromCartDiscountCodes && { coupon: removeFromCartDiscountCodes })
        },
        event: TagsActionType.REMOVE_FROM_CART
    };
};

export default removeFromCartHandler;

import React from 'react';
import type { Address as AddressType } from '@wilm/shared-types/account';
import Typography from 'components/commercetools-ui/atoms/typography';

interface Props {
    address: AddressType | undefined;
    title?: string;
    wrapperClassName?: string;
    containerClassName?: string;
}

const Address: React.FC<Props> = ({ address, title, wrapperClassName, containerClassName }) => {
    if (!address) {
        return <></>;
    }

    return (
        // <div className="border-checkout-border mb-30 border-b pb-16 lg:border-0 lg:pb-15">
        <div className={wrapperClassName}>
            {title && (
                <Typography className="mb-16 text-20" as="h2">
                    {title}
                </Typography>
            )}

            {/* <div className="border-checkout-step-border p-0 text-md md:py-13 lg:border lg:px-15"> */}
            <div className={containerClassName}>
                <p className="">
                    {address?.firstName} {address?.lastName}, {address.phone}
                </p>
                <p className="">
                    {address?.streetName}
                    {address?.additionalAddressInfo && <span className="">{address?.additionalAddressInfo}</span>},
                    <span className="">
                        {address?.postalCode} {address?.city} {address?.region}, {address?.country}
                    </span>
                </p>
            </div>
        </div>
    );
};

export default Address;

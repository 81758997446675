import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

export interface Props {
    markdown: string;
    className?: string;
}

const Markdown: React.FC<Props> = ({ markdown, className }) => {
    return (
        <div className="markdown">
            {/* @ts-expect-error rehypeRaw is not typed correctly in the library https://github.com/orgs/rehypejs/discussions/63 */}
            <ReactMarkdown className={className} rehypePlugins={[rehypeRaw]}>
                {markdown}
            </ReactMarkdown>
        </div>
    );
};

export default Markdown;

import React from 'react';
import { useFormat } from 'helpers/hooks/useFormat';

const Secure = () => {
    const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });

    return (
        <div className="mb-30 hidden border-b border-checkout-step-border pb-30 pt-30 lg:block">
            <h6 className="leading-22 text-22 capitalize">
                {formatCheckoutMessage({ id: 'secure.checkout', defaultMessage: 'Secure checkout' })}
            </h6>
        </div>
    );
};

export default Secure;

import React, { createContext, useMemo } from 'react';
import type { Market } from 'components/commercetools-ui/organisms/header/types';
import useMarket from 'helpers/hooks/useMarket';

const initialMarketState = {
    market: {} as Market,
    markets: [] as Market[],
    handleMarket: {} as (market: Market) => void
};

const MarketContext = createContext(initialMarketState);

const MarketProvider = ({ children }: React.PropsWithChildren) => {
    const { market, markets, handleMarket } = useMarket();

    const value = useMemo(() => ({ market: market!, markets, handleMarket }), [market, markets, handleMarket]);

    return <MarketContext.Provider value={value}>{children}</MarketContext.Provider>;
};

export { MarketContext, MarketProvider };

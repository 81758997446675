import type { FC, ReactNode } from 'react';
import React, { useContext, createContext } from 'react';
import countries from '../../public/countries.json';

interface CountriesProviderProps {
    blacklistedCountries: string[];
    children: ReactNode;
}

export interface CountriesContextShape {
    countries?: Country[];
    countryCodes?: string[];
}

interface Country {
    value: string;
    group: string;
    label: string;
}

const CountriesContext = createContext<CountriesContextShape>({});

const CountriesProvider: FC<CountriesProviderProps> = ({ blacklistedCountries, children }) => {
    const reducedData = countries.reduce(
        (acc, country) => {
            if (!blacklistedCountries.includes(country.value)) {
                acc.countryCodes?.push(country.value);
                acc.countries?.push(country);
            }
            return acc;
        },
        { countries: [] as Country[], countryCodes: [] as string[] } as CountriesContextShape
    );

    return <CountriesContext.Provider value={reducedData}>{children}</CountriesContext.Provider>;
};

export default CountriesProvider;

export const useCountries = () => useContext(CountriesContext);

'use client';

import type { FrontasticImage } from 'frontastic/lib/image/types';
import Footer from 'components/sales-link/organisms/footer';
import type { TasticProps } from 'frontastic/tastics/types';

interface SalesFooterTasticProps {
    logo: FrontasticImage;
}

const SalesLinkFooterTastic = (props: TasticProps<SalesFooterTasticProps>) => {
    return <Footer logo={props.data.logo} />;
};
export default SalesLinkFooterTastic;

'use client';

import React from 'react';
import ProductFilters from 'components/sales-link/organisms/content/products/filters';
import ProductResults from 'components/sales-link/organisms/content/products/results';

const ProductSearch: React.FC = () => {
    return (
        <div className="bg-white p-16">
            <div className="mb-40">
                <ProductFilters />
            </div>
            <div className="mb-40">
                <ProductResults />
            </div>
        </div>
    );
};

export default ProductSearch;

import type { FC } from 'react';
import React, { useContext, createContext, useMemo, useCallback, useState, useEffect } from 'react';
import { useRouter } from 'next/navigation';
import type { SDKResponse } from '@commercetools/frontend-sdk';
import { PaymentMethodType } from '@wilm/shared-types/cart/Payment';
import type { PaymentLinkInfoType } from '@wilm/shared-types/payment-link/PaymentLink';
import { validate } from '@wilm/shared-types/validation-rules';
import { cardFields as initialCardFields, paymentFields } from '@wilm/shared-types/validation-rules/payment';
import type { PaymentFields, CardFields } from '@wilm/shared-types/validation-rules/payment';
import { type FieldErrors, type StringFieldDefinition } from '@wilm/shared-types/validation-rules/types';
import type { PaymentData } from 'components/commercetools-ui/organisms/checkout/provider/payment/types';
import ServerError from 'components/commercetools-ui/organisms/server-error';
import MessageModal, { type MessageModalProps } from 'components/sales-link/organisms/message-modal';
import useSessionStorage from 'helpers/hooks/useSessionStorage';
import { sdk } from 'sdk';
import type { SWRResponse } from 'swr';
import useSWR from 'swr';
import { revalidateOptions } from 'frontastic';
import { useCybersourceContext } from '../cybersource';

export interface PaymentLinkInfoPayload {
    hash: string;
    userAgent: string;
    requestMethod: string;
    requestUrl: string;
}

interface PaymentLinkProviderProps {
    paymentLinkInfoPayload: PaymentLinkInfoPayload;
    children: React.ReactNode;
}

interface PaymentLinkContextShape {
    paymentLinkInfo: PaymentLinkInfoType;
    paymentLinkInfoLoading: boolean;
    cardFields: CardFields;
    isPONumberRequired: boolean;
    poNumberField: PaymentFields['poNumber'];
    handlePoNumberValueChange: (value: string) => void;
    handlePayment: () => Promise<{
        isError: boolean;
        needsToStartFromBeginning: boolean;
        fieldsErrors: FieldErrors;
    }>;
    setCardField: (field: keyof CardFields, value: string) => void;
    setPurchaseOrderNumber: (poNumberField: PaymentFields['poNumber']) => Promise<{
        isError: boolean;
        needsToStartFromBeginning: boolean;
        fieldsErrors: FieldErrors;
    }>;
    setIsTermsAccepted: (isTermsAccepted: boolean) => void;
}

const PaymentLinkContext = createContext<PaymentLinkContextShape>({} as PaymentLinkContextShape);

const PaymentLinkProvider: FC<PaymentLinkProviderProps> = ({ paymentLinkInfoPayload, children }) => {
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [messageData, setMessageData] = useState<MessageModalProps['data']>({ message: '', type: 'error' });
    const [refreshOnClose, setRefreshOnClose] = useState(false);

    const [poNumberField, setPoNumberField] = useSessionStorage('poNumberField', paymentFields.poNumber);

    const router = useRouter();

    const getPaymentLinkInfoResult: SWRResponse<SDKResponse<PaymentLinkInfoType>> = useSWR(
        'action/salesLink/getPaymentLinkInfo',
        () =>
            sdk.callAction({
                actionName: 'salesLink/getPaymentLinkInfo',
                payload: paymentLinkInfoPayload
            }) as unknown as SDKResponse<PaymentLinkInfoType>,
        revalidateOptions
    );

    const paymentLinkInfoLoading = getPaymentLinkInfoResult.isValidating;
    const paymentLinkInfo = useMemo(
        () =>
            (getPaymentLinkInfoResult.data?.isError ? ({} as PaymentLinkInfoType) : getPaymentLinkInfoResult.data?.data) ??
            ({} as PaymentLinkInfoType),
        [getPaymentLinkInfoResult.data]
    );

    // setPaymentData will be used when we have more payment methods
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [paymentData, setPaymentData] = useState<PaymentData>({ type: PaymentMethodType.CARD } as PaymentData);

    const isPONumberRequired = useMemo(() => !!paymentLinkInfo.order?.custom?.fields?.isPONumberRequired, [paymentLinkInfo]);

    const orderPoNumber = useMemo(() => paymentLinkInfo.order?.purchaseOrderNumber, [paymentLinkInfo]);

    useEffect(() => {
        if (orderPoNumber && !poNumberField.value) {
            setPoNumberField({
                ...poNumberField,
                value: orderPoNumber
            });
        }
    }, [poNumberField, orderPoNumber]);

    const [cardFields, setCardFields] = useState(initialCardFields);

    const { makeCCPayment } = useCybersourceContext();

    const handlePoNumberValueChange = useCallback(
        (value: string) => {
            setPoNumberField({
                ...poNumberField,
                value
            });
        },
        [poNumberField]
    );

    const setCardField = useCallback((field: keyof CardFields, value: string) => {
        if (field === 'expiryYear') {
            value = '20' + value;
        }
        setCardFields(prev => ({
            ...prev,
            [field]: {
                ...prev[field],
                value
            } as StringFieldDefinition
        }));
    }, []);

    const openErrorModalWithError = useCallback((error: string, refreshOnClose: boolean) => {
        setMessageData({
            heading: 'ERROR',
            message: error,
            type: 'error',
            showCloseButton: true,
            closeButtonText: 'I understand'
        });
        setIsErrorModalOpen(true);
        setRefreshOnClose(refreshOnClose);
    }, []);

    const handlePayment = useCallback(async () => {
        console.log('---> poNumberField.value', poNumberField.value);

        // check isTermsAccepted
        if (!isTermsAccepted) {
            openErrorModalWithError('Please accept Terms and Conditions to proceed', false);
            return {
                isError: true,
                needsToStartFromBeginning: false,
                fieldsErrors: {}
            };
        }

        if (isPONumberRequired) {
            const poNumber = await setPurchaseOrderNumber(poNumberField);
            if (poNumber.isError) {
                return poNumber;
            }
        }

        console.log('---> paymentData.type', paymentData.type);

        if (paymentData.type !== PaymentMethodType.CARD) {
            openErrorModalWithError('Invalid payment method', true);
            return {
                isError: true,
                needsToStartFromBeginning: true,
                fieldsErrors: {}
            };
        }
        console.log('---> payment with card');

        const fieldsErrors = validateCardFields(cardFields);

        console.log('---> fieldsErrors', fieldsErrors);

        if (Object.keys(fieldsErrors).length) {
            return {
                isError: true,
                needsToStartFromBeginning: false,
                fieldsErrors
            };
        }

        const options = {
            expirationMonth: cardFields.expiryMonth.value,
            expirationYear: cardFields.expiryYear.value
        };

        const paymentResult = await makeCCPayment(options, paymentLinkInfo);

        console.log('---> makeCCPayment paymentResult', paymentResult);

        if (!paymentResult) {
            openErrorModalWithError('Payment failed', true);
            return {
                isError: true,
                needsToStartFromBeginning: true,
                fieldsErrors: {}
            };
        }

        if (paymentResult.isError && paymentResult.needsToStartFromBeginning) {
            openErrorModalWithError('Payment failed', true);
            return {
                isError: true,
                needsToStartFromBeginning: true,
                fieldsErrors: {}
            };
        }

        if (!paymentResult.isError) {
            if (typeof window !== 'undefined') {
                window.sessionStorage.setItem('salesLinkLastPlacedOrder', JSON.stringify(paymentResult.order));
            }
            router.push('/sales-link/thank-you');
        }

        return paymentResult;
    }, [
        isTermsAccepted,
        poNumberField,
        isPONumberRequired,
        paymentLinkInfo,
        paymentData,
        cardFields,
        makeCCPayment,
        openErrorModalWithError
    ]);

    const validateCardFields = (cardFields: CardFields) => {
        const fieldsErrors: FieldErrors = {};
        Object.keys(cardFields).forEach(key => {
            const field = cardFields[key];
            const errors = validate(field, cardFields);

            if (Object.keys(errors).length) {
                fieldsErrors[key] = errors;
            }
        });

        return fieldsErrors;
    };

    const setPurchaseOrderNumber = useCallback(
        async (poNumberField: PaymentFields['poNumber']) => {
            //validate po number
            const error = validate(poNumberField, paymentFields);

            console.log('---> setPurchaseOrderNumber validate po number error', error);

            if (Object.keys(error).length) {
                return {
                    isError: true,
                    needsToStartFromBeginning: false,
                    fieldsErrors: {
                        [poNumberField.name]: error
                    }
                };
            }

            const response: SDKResponse<any> = await sdk.callAction({
                actionName: 'salesLink/setPurchaseOrderNumber',
                payload: {
                    purchaseOrderNumber: poNumberField.value,
                    hash: paymentLinkInfo.hash,
                    unlockId: paymentLinkInfo.unlockId
                }
            });

            console.log('---> setPurchaseOrderNumber response', response);

            if (response.isError) {
                openErrorModalWithError(
                    'Error setting purchase order number, please try again. If the problem persists, contact support.',
                    false
                );
                return {
                    isError: true,
                    needsToStartFromBeginning: false,
                    fieldsErrors: {}
                };
            }

            if (response.data?.isError && response.data?.fieldsErrors) {
                return {
                    isError: true,
                    needsToStartFromBeginning: false,
                    fieldsErrors: response.data.fieldsErrors
                };
            }

            return {
                isError: false,
                needsToStartFromBeginning: false,
                fieldsErrors: {}
            };
        },
        [paymentLinkInfo]
    );

    const onErrorModalClose = () => {
        if (refreshOnClose) {
            window.location.reload();
        }
        setIsErrorModalOpen(false);
    };

    const value = useMemo(
        () => ({
            paymentLinkInfo,
            paymentLinkInfoLoading,
            cardFields,
            isPONumberRequired,
            poNumberField,
            handlePoNumberValueChange,
            handlePayment,
            setCardField,
            setPurchaseOrderNumber,
            setIsTermsAccepted
        }),
        [
            paymentLinkInfo,
            paymentLinkInfoLoading,
            cardFields,
            isPONumberRequired,
            poNumberField,
            handlePoNumberValueChange,
            handlePayment,
            setCardField,
            setPurchaseOrderNumber,
            setIsTermsAccepted
        ]
    );

    if (getPaymentLinkInfoResult.error) {
        return <ServerError refreshLink="/" />;
    }

    return (
        <PaymentLinkContext.Provider value={value}>
            <MessageModal data={messageData} isOpen={isErrorModalOpen} closeModal={onErrorModalClose} />
            {children}
        </PaymentLinkContext.Provider>
    );
};

export default PaymentLinkProvider;

export const usePaymentLinkContext = () => useContext(PaymentLinkContext);

import addPaymentInfoHandler from './actions/addPaymentInfo';
import addShippingInfoHandler from './actions/addShippingInfo';
import addToCartHandler from './actions/addToCart';
import beginCheckoutHandler from './actions/beginCheckout';
import clearHandler from './actions/clear';
import loginHandler from './actions/login';
import purchaseHandler from './actions/purchase';
import removeFromCartHandler from './actions/removeFromCart';
import signUpHandler from './actions/signup';
import TagsActionType from './actions/types/tagsActionType';
import type { GTMTag, GTMAction, GTMActionPayload } from './interfaces/gtmTag';

const GTM_LOCAL_STORAGE_KEY = 'GTM_ATTRIBUTES';
declare global {
    interface Window {
        dataLayer: Record<string, any>[];
        gtmDebugMode: boolean;
    }
}

const pushToLocalStorage = (tag: GTMTag) => {
    const storageData = localStorage.getItem(GTM_LOCAL_STORAGE_KEY);
    let parsedStorageData = [];
    if (storageData) {
        parsedStorageData = JSON.parse(storageData);
    }
    parsedStorageData.push(tag);
    localStorage.setItem(GTM_LOCAL_STORAGE_KEY, JSON.stringify(parsedStorageData));
};

const pushToDataLayer = (tag: GTMTag) => {
    const isBrowser = typeof window !== 'undefined';
    if (isBrowser && !Array.isArray(window.dataLayer)) {
        return false;
    }
    if (!window.dataLayer) {
        pushToLocalStorage(tag);
    } else {
        const storageData = localStorage.getItem(GTM_LOCAL_STORAGE_KEY);

        const parsedStorageData = JSON.parse(storageData ?? '[]');

        const tags = parsedStorageData ? parsedStorageData.concat(tag) : [tag];
        tags.forEach((tag: GTMTag) => {
            if (window.gtmDebugMode) {
                console.info('> Pushing to GTM:');
                console.table(tag);
            }
            window.dataLayer.push(tag);
        });
        localStorage.removeItem(GTM_LOCAL_STORAGE_KEY);
    }
};

const GTMActionConfig: Record<TagsActionType, (payload: { payload: GTMActionPayload }) => GTMTag> = {
    [TagsActionType.SIGN_UP]: ({ payload }): GTMTag => signUpHandler({ payload }),
    [TagsActionType.ADD_TO_CART]: ({ payload }): GTMTag => addToCartHandler({ payload }),
    [TagsActionType.REMOVE_FROM_CART]: ({ payload }): GTMTag => removeFromCartHandler({ payload }),
    [TagsActionType.ADD_PAYMENT_INFO]: ({ payload }): GTMTag => addPaymentInfoHandler({ payload }),
    [TagsActionType.ADD_SHIPPING_INFO]: ({ payload }): GTMTag => addShippingInfoHandler({ payload }),
    [TagsActionType.BEGIN_CHECKOUT]: ({ payload }): GTMTag => beginCheckoutHandler({ payload }),
    [TagsActionType.PURCHASE]: ({ payload }): GTMTag => purchaseHandler({ payload }),
    [TagsActionType.LOGIN]: ({ payload }): GTMTag => loginHandler({ payload }),
    [TagsActionType.CLEAR]: (): GTMTag => clearHandler()
};

export default function track(action: GTMAction): void {
    const tag: GTMTag = GTMActionConfig[action.type]({ payload: action.payload });

    if (Object.keys(tag).length) {
        pushToDataLayer(tag);
    }
}

import type { Variant } from '@wilm/shared-types/product';
import { AttributeEnum } from '@wilm/shared-types/product/Attributes';
import type { Attribute } from '@wilm/shared-types/product/Attributes';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { formatDate } from 'helpers/dateHelpers';
import { useFormat } from 'helpers/hooks/useFormat';

export const useProductAttribute = () => {
    const { formatMessage } = useFormat({ name: 'product' });

    const getFormattedAttributesFromVariant = (attributes: AttributeEnum[], variant: Variant, selectedCurrency?: string): Attribute[] => {
        const formattedAttributes: Attribute[] = [];
        for (const attribute of attributes) {
            formattedAttributes.push(getFormattedAttributeFromVariant(attribute, variant, selectedCurrency));
        }
        return formattedAttributes;
    };

    const getFormattedAttributeFromVariant = (attribute: AttributeEnum, variant: Variant, selectedCurrency?: string): Attribute => {
        const price = selectedCurrency ? variant.prices?.[selectedCurrency] : undefined;
        const priceFormatted = price ? CurrencyHelpers.formatForCurrency(price) : '-';
        const attributeMap = {
            [AttributeEnum.SHORT_DESCRIPTION]: variant.attributes?.shortDescription ?? '-',
            [AttributeEnum.START_DATE]: variant.attributes?.startDate ? formatDate(variant.attributes.startDate) : '-',
            [AttributeEnum.END_DATE]: variant.attributes?.endDate ? formatDate(variant.attributes.endDate) : '-',
            [AttributeEnum.ADMINISTRATE_EVENT_COHORT]: variant.attributes?.administrateEventCohort ?? '-',
            [AttributeEnum.SKU]: variant.sku,
            [AttributeEnum.LEARNING_MODE]: variant.attributes?.learningMode.label ?? '-',
            [AttributeEnum.EVENT_TYPE]: variant.attributes?.eventType.label ?? '-',
            [AttributeEnum.PRICE]: priceFormatted
        };

        return {
            label: formatMessage({ id: attribute, defaultMessage: attribute }),
            value: attributeMap[attribute]
        };
    };

    return { getFormattedAttributesFromVariant, getFormattedAttributeFromVariant };
};

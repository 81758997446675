import React, { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'next/navigation';
import type { Discount } from '@wilm/shared-types/cart';
import type { AccordionProps } from 'components/commercetools-ui/atoms/accordion';
import AccordionBtn from 'components/commercetools-ui/atoms/accordion';
import Button from 'components/commercetools-ui/atoms/button';
import CloseIcon from 'components/icons/close';
import useClassNames from 'helpers/hooks/useClassNames';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic';

export interface Props {
    className?: string;
    accordionProps?: AccordionProps;
}

const DiscountForm: React.FC<Props> = ({ className, accordionProps }) => {
    const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

    const [code, setCode] = useState('');
    const [discounts, setDiscounts] = useState<Discount[]>([]);
    const [codeIsInvalid, setCodeIsInvalid] = useState(false);
    const [codeEmptyError, setCodeEmptyError] = useState(false);
    const { redeemDiscountCode, removeDiscountCode, updateCart, data } = useCart();

    const [processing, setProcessing] = useState(false);

    const searchParams = useSearchParams();
    const step = searchParams.get('step');
    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setDiscounts(data?.discountCodes ?? []);
    }, [data]);

    const inputClassName = useClassNames([
        'h-40 w-full rounded-sm border px-10 py-12 text-14 placeholder:text-secondary-black focus:outline-none disabled:bg-neutral-300',
        codeIsInvalid || codeEmptyError ? 'border-input-error text-input-error focus:border-input-error' : 'border-checkout-border'
    ]);

    const discountsContainerClassName = useClassNames([
        'mt-8 flex flex-wrap justify-items-start gap-12',
        discounts?.length === 0 ? 'pt-0' : 'pt-4'
    ]);

    const containerClassName = useClassNames(['border-checkout-border border-t py-16 text-14 text-16', className]);

    const onApplyDiscount = () => {
        if (processing || !code) return;

        setProcessing(true);

        redeemDiscountCode?.(code)
            .then(async () => {
                await recalculateTaxes();
                setCode('');
            })
            .catch(() => {
                setCodeIsInvalid(true);
            })
            .finally(() => {
                setProcessing(false);
            });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCode(e.target.value);
        setCodeIsInvalid(false);
    };

    const handleRemove = async (discount: Discount) => {
        await removeDiscountCode?.(discount);
        await recalculateTaxes();
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (ref?.current?.value === '') {
            setCodeEmptyError(true);
        } else {
            setCodeEmptyError(false);
            onApplyDiscount();
        }
    };

    const resetCode = () => {
        setCode('');
        setCodeIsInvalid(false);
    };

    const recalculateTaxes = async () => {
        if (step && data?.billingAddress) {
            await updateCart({
                billing: data?.billingAddress
            });
        }
    };

    return (
        <div className={containerClassName}>
            <AccordionBtn
                closedSectionTitle={formatCartMessage({ id: 'discount.apply', defaultMessage: 'Apply a discount' })}
                buttonClassName="text-secondary-black"
                iconClassName="w-24"
                {...accordionProps}
            >
                <form className="flex flex-wrap pt-16" onSubmit={handleSubmit}>
                    <div className="relative box-border w-2/3 pr-8">
                        <input
                            className={inputClassName}
                            value={code}
                            placeholder={formatCartMessage({
                                id: 'cart.discount.enter',
                                defaultMessage: 'Enter discount code'
                            })}
                            onChange={handleChange}
                            disabled={processing}
                            ref={ref}
                        />
                        {codeIsInvalid && (
                            <button
                                className="absolute right-16 top-1/2 -translate-y-1/2"
                                onClick={e => {
                                    e.stopPropagation();
                                    resetCode();
                                }}
                            >
                                <CloseIcon className=" size-10 cursor-pointer" />
                            </button>
                        )}
                    </div>
                    <Button className="w-1/3" onClick={handleSubmit} size="s">
                        {formatCartMessage({ id: 'apply', defaultMessage: 'Apply' })}
                    </Button>
                </form>
                {codeIsInvalid && (
                    <p className="mt-16 font-body text-12 font-medium leading-normal text-input-error">
                        {formatCartMessage({ id: 'codeNotValid', defaultMessage: 'The discount code is not valid' })}
                    </p>
                )}
                {codeEmptyError && (
                    <p className="mt-16 font-body text-12 font-medium leading-normal text-input-error">
                        {formatCartMessage({ id: 'codeEmpty', defaultMessage: 'Enter your code' })}
                    </p>
                )}

                {discounts && !!discounts.length && (
                    <div className={discountsContainerClassName}>
                        {discounts.map(discount => (
                            <div key={discount.discountId} className="w-full">
                                <div className="mr-2 flex w-full justify-between gap-8 rounded-sm bg-white px-8 py-4">
                                    <label className="text-12 leading-[16px] text-secondary-black">{discount.code}</label>
                                    <button type="button" onClick={() => handleRemove(discount)}>
                                        <p className="text-12 lowercase leading-[16px] hover:underline">
                                            {formatCartMessage({ id: 'remove', defaultMessage: 'Remove' })}
                                        </p>
                                    </button>
                                </div>

                                {discount.state === 'DoesNotMatchCart' && (
                                    <p className="mx-8 mt-10 text-sm text-input-error">
                                        {formatCartMessage({
                                            id: 'code.does.not.match',
                                            defaultMessage: 'The discount code does not match the cart'
                                        })}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </AccordionBtn>
        </div>
    );
};

export default DiscountForm;

import type { FC } from 'react';
import React, { useState, useEffect } from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import Radio from 'components/commercetools-ui/atoms/radio';
import { useCart } from 'frontastic';

interface DelegateSelectionProps {
    itemId: string;
    qty: number | undefined;
    hasDelegateErrors?: boolean;
}

const DelegateSelection: FC<DelegateSelectionProps> = ({ itemId, qty, hasDelegateErrors }) => {
    const { delegateObj, handleIsDelegateChange } = useCart();
    const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
    const [isQtyLarger, setIsQtyLarger] = useState(false);

    useEffect(() => {
        if (qty) setIsQtyLarger(qty > 1);
    }, [qty]);

    return (
        <>
            <p className="mb-14 mt-16 text-md font-bold text-checkout-delegates">
                {formatCartMessage({ id: 'delegate.label', defaultMessage: 'Who are you enroling onto this product?' })}
            </p>
            <div className="mb-4 grid gap-12 md:max-w-[570px] md:grid-cols-2">
                <Radio
                    srOnly={true}
                    id={`is_delegate_${itemId}`}
                    name={`${itemId}_isDelegate`}
                    onChange={() => {
                        handleIsDelegateChange(itemId, true);
                    }}
                    checked={delegateObj?.[itemId] === true}
                    className="flex h-full min-w-[100%] cursor-pointer rounded-md border border-gray-200 px-16 py-12 peer-checked:border-input-border"
                >
                    <div>
                        <p className="mb-4 font-bold">
                            {isQtyLarger
                                ? formatCartMessage({ id: 'delegate.myself.others', defaultMessage: 'Myself & others' })
                                : formatCartMessage({ id: 'delegate.myself', defaultMessage: 'Myself' })}
                        </p>
                        <p className="text-sm">
                            {isQtyLarger
                                ? formatCartMessage({
                                      id: 'delegate.myself.others.copy',
                                      defaultMessage: 'I am enroling myself and other people onto this product'
                                  })
                                : formatCartMessage({
                                      id: 'delegate.myself.copy',
                                      defaultMessage: 'I am enroling myself onto this product'
                                  })}
                        </p>
                    </div>
                </Radio>
                <Radio
                    srOnly={true}
                    id={`not_delegate_${itemId}`}
                    name={`${itemId}_isDelegate`}
                    onChange={() => {
                        handleIsDelegateChange(itemId, false);
                    }}
                    checked={delegateObj?.[itemId] === false}
                    className="flex h-full min-w-[100%] cursor-pointer rounded-md border border-gray-200 px-16 py-12 peer-checked:border-input-border"
                >
                    <div>
                        <p className="mb-4 font-bold">
                            {isQtyLarger
                                ? formatCartMessage({ id: 'delegate.others.only', defaultMessage: 'Others only' })
                                : formatCartMessage({ id: 'delegate.others', defaultMessage: 'Others' })}
                        </p>
                        <p className="text-sm">
                            {isQtyLarger
                                ? formatCartMessage({
                                      id: 'delegate.others.only.copy',
                                      defaultMessage: 'I am enroling other people onto this product'
                                  })
                                : formatCartMessage({
                                      id: 'delegate.others.copy',
                                      defaultMessage: 'I am enroling somebody onto this product'
                                  })}
                        </p>
                    </div>
                </Radio>
                {hasDelegateErrors && (
                    <div data-error="true">
                        <p className="relative mt-4 text-sm leading-tight text-input-error">
                            {formatCartMessage({
                                id: 'delegates.selection.error',
                                defaultMessage: 'Select who is enroling for this product'
                            })}
                        </p>
                    </div>
                )}
            </div>
        </>
    );
};

export default DelegateSelection;

import React from 'react';

interface Props {
    title: string;
    message: string;
}

const FullPageError: React.FC<Props> = ({ title, message }) => {
    return (
        <div className=" flex min-h-[calc(100dvh-230px)] items-center justify-center p-16 text-center md:min-h-[calc(100dvh-290px)] ">
            <div className="md:w-[600px]">
                <h1 className="mb-10 text-l">{title}</h1>
                <p className="text-18">{message}</p>
            </div>
        </div>
    );
};

export default FullPageError;

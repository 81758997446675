import type { Currency } from '@commercetools/frontend-sdk/lib/types/Currency';
import type { GTMTag, GTMActionPayload, TrackAddPaymentInfoPayloadType } from './../interfaces/gtmTag';
import TagsActionType from './types/tagsActionType';
import getDiscountCodesString from '../utils/getDiscountCodesString';
import getGtmItems from '../utils/getGtmItems';
import getGtmValue from '../utils/getGtmValue';

const addPaymentInfoHandler = ({ payload }: { payload: GTMActionPayload }): GTMTag => {
    const addPaymentInfoPayload = payload as TrackAddPaymentInfoPayloadType;
    const addPaymentInfoCart = addPaymentInfoPayload.cart;
    const addPaymentInfoDiscountCodes = getDiscountCodesString(addPaymentInfoCart?.discountCodes ?? []);
    const addPaymentInfoItems = getGtmItems(addPaymentInfoCart?.lineItems ?? []);
    const addPaymentInfoValue = getGtmValue(addPaymentInfoCart.sum!);
    const addPaymentInfoCurrency = addPaymentInfoCart?.sum?.currencyCode as Currency;
    const paymentMethod = addPaymentInfoPayload.paymentMethod;

    return {
        ecommerce: {
            currency: addPaymentInfoCurrency,
            items: addPaymentInfoItems,
            value: addPaymentInfoValue,
            payment_type: paymentMethod,
            ...(addPaymentInfoDiscountCodes && { coupon: addPaymentInfoDiscountCodes })
        },
        event: TagsActionType.ADD_PAYMENT_INFO
    };
};

export default addPaymentInfoHandler;

import type { Currency } from '@commercetools/frontend-sdk/lib/types/Currency';

interface LocalizationMapping {
    locale: string;
    currency: Currency;
    currencyCode: string;
    countryName: string;
    countryCode: string;
    countryKey: string;
}

const localizationMapper = {
    en: {
        locale: 'en_GB',
        currency: 'GBP',
        currencyCode: '£',
        countryCode: 'GB',
        countryName: 'United Kingdom',
        countryKey: 'gb'
    }
} as Record<string, LocalizationMapping>;

export const getLocalizationInfo = (locale: string) => {
    if (!(locale in localizationMapper)) {
        return localizationMapper.en;
    }

    return localizationMapper[locale];
};

import type { FC } from 'react';
import React from 'react';
import type { LineItem } from '@wilm/shared-types/cart/LineItem';
import Price from 'components/commercetools-ui/atoms/product-types/price';
import ProductName from 'components/commercetools-ui/atoms/product-types/product-name';
import Quantity from 'components/commercetools-ui/atoms/product-types/quantity';

interface AdhocCartItemProps {
    item: LineItem;
    counterClassName: string;
    updateCartItem?: (newQuantity: number) => Promise<void>;
    removeItem?: (lineItemId: string) => void;
    disabledQuantity?: boolean;
    showPrice?: boolean;
    showQuantity?: boolean;
    showQuantityEdit?: boolean;
    inventoryCheckError?: string;
}

const AdhocCartItem: FC<AdhocCartItemProps> = ({
    item = {} as LineItem,
    counterClassName = '',
    updateCartItem = () => {},
    removeItem,
    disabledQuantity = false,
    showPrice = true,
    showQuantity = false,
    showQuantityEdit = true,
    inventoryCheckError
}) => (
    <>
        <ProductName lineItemId={item.lineItemId} removeItem={removeItem} productName={item.name!} />

        {/* Price */}
        {showPrice && <Price price={item.price as number} discountedPrice={item.discountedPrice as number} />}

        {/* Quantity */}
        <Quantity
            count={item.count ?? 1}
            counterClassName={counterClassName}
            updateCartItem={updateCartItem}
            disabledQuantity={disabledQuantity}
            showQuantityEdit={showQuantityEdit}
            showQuantity={showQuantity}
            inventoryCheckError={inventoryCheckError}
        />
    </>
);

export default AdhocCartItem;

import type { Currency } from '@commercetools/frontend-sdk/lib/types/Currency';
import type { GTMTag, GTMActionPayload, TrackAddToCartPayloadType } from './../interfaces/gtmTag';
import TagsActionType from './types/tagsActionType';
import getDiscountCodesString from '../utils/getDiscountCodesString';
import getGtmItems from '../utils/getGtmItems';
import getGtmValue from '../utils/getGtmValue';

const addToCartHandler = ({ payload }: { payload: GTMActionPayload }): GTMTag => {
    const addToCartPayload = payload as TrackAddToCartPayloadType;
    const addToCartItem = { ...addToCartPayload.item, count: addToCartPayload.quantity };
    const addToCartDiscountCodes = getDiscountCodesString(addToCartItem?.discounts ?? []);
    const addToCartItems = getGtmItems([addToCartItem]);
    const addToCartValue = getGtmValue(addToCartItem.discountedPrice! || addToCartItem.price!);
    const addToCartCurrency = addToCartItem?.price?.currencyCode as Currency;

    return {
        ecommerce: {
            currency: addToCartCurrency,
            items: addToCartItems,
            value: addToCartValue,
            ...(addToCartDiscountCodes && { coupon: addToCartDiscountCodes })
        },
        event: TagsActionType.ADD_TO_CART
    };
};

export default addToCartHandler;

enum TagsActionType {
    ADD_TO_CART = 'add_to_cart',
    ADD_PAYMENT_INFO = 'add_payment_info',
    ADD_SHIPPING_INFO = 'add_shipping_info',
    BEGIN_CHECKOUT = 'begin_checkout',
    PURCHASE = 'purchase',
    REMOVE_FROM_CART = 'remove_from_cart',
    SIGN_UP = 'sign_up',
    LOGIN = 'login',
    CLEAR = 'clear'
}

export default TagsActionType;

import { fields as billingAddressFields } from '@wilm/shared-types/validation-rules/account/addresses';
import { validate } from '@wilm/shared-types/validation-rules';
import type { CustomerAddressFields } from '@wilm/shared-types/validation-rules/account/addresses';
import { BundleMode } from '@wilm/shared-types/product/Bundle';
import type { Address } from '@wilm/shared-types/account';
import type { BundleLineItemError } from '@wilm/shared-types/sales-link/SalesLinkCart';

export const hasErrorsInAddress = (address?: Address) => {
    if (!address) {
        return true;
    }
    const billingFieldsWithValues: CustomerAddressFields = Object.keys(billingAddressFields).reduce((acc, key) => {
        const fieldKey = key as keyof CustomerAddressFields;

        acc[fieldKey] = {
            ...billingAddressFields[fieldKey],
            value: address[fieldKey as keyof Address]
        } as (typeof billingAddressFields)[typeof fieldKey];
        return acc;
    }, {} as CustomerAddressFields);

    // loop trough all fields and validate them if error return true
    for (const field of Object.values(billingFieldsWithValues)) {
        const error = validate(field, billingFieldsWithValues);

        if (Object.keys(error).length) {
            return true;
        }
    }
    return false;
};

export const validateSelectedBundleComponent = (
    bundleComponentIds: string[],
    selectedBundleComponents: Record<string, string>,
    selectedCommencementDate: string,
    bundleMode: BundleMode
) => {
    const error: BundleLineItemError = {
        error: {
            code: ''
        },
        missingSelectedComponentIds: []
    };

    if (bundleMode === BundleMode.COMMENCEMENT_DATE && !selectedCommencementDate) {
        error.error.code = 'error.bundle.select.one';
    }
    const misslingSelectedValues = bundleComponentIds.filter(id => !selectedBundleComponents[id]);

    if (bundleMode === BundleMode.SELECT_COMPONENTS && misslingSelectedValues.length) {
        error.error.code = 'error.bundle.select.for.each';
        error.missingSelectedComponentIds = misslingSelectedValues;
    }

    return error;
};

import type { FC } from 'react';
import { useEffect } from 'react';
import { XMarkIcon as CloseIcon } from '@heroicons/react/24/solid';
import type { Props as ReactModalProps } from 'react-modal';
import ReactModal from 'react-modal';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';

export interface Props extends ReactModalProps {
    closeButton?: boolean;
    className?: string;
    dataCloseCy?: string;
}

const Modal: FC<Props> = ({ children, style, preventScroll, closeButton, className = '', ...props }) => {
    const [isDesktopSize] = useMediaQuery(desktop);

    const modalStyle: ReactModalProps['style'] = {
        overlay: {
            zIndex: 51,
            backgroundColor: 'rgba(127 ,127, 127, .3)',
            ...style?.overlay
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            maxWidth: isDesktopSize ? 800 : '100%',
            position: 'relative',
            padding: 0,
            ...style?.content
        }
    };

    useEffect(() => {
        if (preventScroll) document.body.style.overflow = props.isOpen ? 'hidden' : 'auto';
    }, [props.isOpen, preventScroll]);

    return (
        <ReactModal {...props} ariaHideApp={false} style={modalStyle} preventScroll={preventScroll} className={`${className} relative`}>
            {closeButton && (
                <CloseIcon
                    className="absolute right-20 top-20 size-24 cursor-pointer text-secondary-black"
                    data-cy={props.dataCloseCy}
                    onClick={props.onRequestClose}
                />
            )}
            {children}
        </ReactModal>
    );
};

export default Modal;

import type { GTMTag, GTMActionPayload, TrackSignUpPayloadType } from './../interfaces/gtmTag';
import TagsActionType from './types/tagsActionType';

const signUpHandler = ({ payload }: { payload: GTMActionPayload }): GTMTag => {
    const signUpPayload = payload as TrackSignUpPayloadType;
    const tag = {
        ecommerce: {
            method: signUpPayload.method
        },
        event: TagsActionType.SIGN_UP
    };

    return tag;
};

export default signUpHandler;

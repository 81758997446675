import React, { useState } from 'react';
import type { FC } from 'react';
import Button from 'components/commercetools-ui/atoms/button';
import Modal from 'components/commercetools-ui/atoms/modal';

export interface Props {
    isOpen: boolean;
    closeModal: () => void;
    paymentLink: string;
    learnersLink?: string;
}

enum CopyKeyEnum {
    PAYMENT_LINK = 'paymentLink',
    LEARNERS_LINK = 'learnersLink'
}

const PaymentLinkModal: FC<Props> = ({ isOpen, closeModal, paymentLink, learnersLink }) => {
    const [copyedKey, setCopyedKey] = useState<CopyKeyEnum | null>(null);
    const [copyError, setCopyError] = useState(false);
    const handleCopy = async (type: CopyKeyEnum, value: string) => {
        try {
            await navigator.clipboard.writeText(value);
            setCopyedKey(type);
        } catch (error) {
            setCopyError(true);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            className="relative w-[90%] rounded-md bg-white"
            style={{ content: { maxWidth: '768px' } }}
            preventScroll={true}
        >
            <div className="p-16 text-base md:p-40">
                <p className="mb-20 text-lg font-bold">Customer payment link:</p>
                <div className="mb-30 flex">
                    <p className="mr-8 flex-grow break-all rounded-md bg-neutral-200 p-12">{paymentLink}</p>
                    <div className="flex flex-col justify-center">
                        <Button
                            className="h-fit w-130"
                            onClick={() => {
                                void handleCopy(CopyKeyEnum.PAYMENT_LINK, paymentLink);
                            }}
                            variant="primary"
                        >
                            {copyedKey === CopyKeyEnum.PAYMENT_LINK ? 'Copied' : 'Copy'}
                        </Button>
                    </div>
                </div>

                {learnersLink && (
                    <>
                        <p className="mb-20 text-lg font-bold">Learners page link:</p>
                        <div className="mb-30 flex">
                            <p className="mr-8 flex-grow break-all rounded-md bg-neutral-200 p-12">{learnersLink}</p>
                            <div className="flex flex-col justify-center">
                                <Button
                                    className="h-fit w-130"
                                    onClick={() => {
                                        void handleCopy(CopyKeyEnum.LEARNERS_LINK, learnersLink);
                                    }}
                                    variant="primary"
                                >
                                    {copyedKey === CopyKeyEnum.LEARNERS_LINK ? 'Copied' : 'Copy'}
                                </Button>
                            </div>
                        </div>
                    </>
                )}

                <div className="flex justify-end">
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                    </Button>
                </div>

                {copyError && <p className="mt-10 text-sm text-input-error">Unable to copy from keyboard</p>}
            </div>
        </Modal>
    );
};

export default PaymentLinkModal;

import React from 'react';
import type { Reference } from 'types/reference';
import LoggedIn from './loggedin';
interface Props {
    loggedIn: boolean;
    accountLinkLabel?: string;
    accountLink?: Reference;
    accountLogoutLabel?: string;
    wishlistLinkLabel?: string;
    wishlistLink?: Reference;
    resetFlag: () => void;
}

const AccountDropdown: React.FC<Props> = ({
    loggedIn,
    accountLinkLabel,
    accountLink,
    accountLogoutLabel,
    wishlistLinkLabel,
    wishlistLink,
    resetFlag
}) => {
    return loggedIn ? (
        <LoggedIn
            accountLinkLabel={accountLinkLabel}
            accountLink={accountLink}
            accountLogoutLabel={accountLogoutLabel}
            wishlistLinkLabel={wishlistLinkLabel}
            wishlistLink={wishlistLink}
            resetFlag={resetFlag}
        />
    ) : (
        <></>
    );
};

export default AccountDropdown;

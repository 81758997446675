'use client';
import type { FC } from 'react';
import React, { useContext, createContext } from 'react';
import type { BrandSettings } from '@wilm/shared-types/settings/BrandSettings';

interface BrandSettingsProviderProps {
    brandSettings: BrandSettings;
    children: React.ReactNode;
}

const BrandSettingsContext = createContext<BrandSettings>({} as BrandSettings);

const BrandSettingsProvider: FC<BrandSettingsProviderProps> = ({ brandSettings, children }) => {
    return <BrandSettingsContext.Provider value={brandSettings}>{children}</BrandSettingsContext.Provider>;
};

export default BrandSettingsProvider;

export const useBrandSettingsContext = () => useContext(BrandSettingsContext);

import { useCallback } from 'react';

const useCloseFlyouts = () => {
    const closeFlyouts = useCallback(() => {
        //Trigger an `ESC` key click to close any active flyouts
        const event = new KeyboardEvent('keyup', { key: 'Escape' });
        document.dispatchEvent(event);
    }, []);

    return closeFlyouts;
};

export default useCloseFlyouts;

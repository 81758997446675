import type { FC } from 'react';
import React from 'react';
import Input from 'components/commercetools-ui/atoms/input';
import Typography from 'components/commercetools-ui/atoms/typography';

interface QuantityProps {
    counterClassName: string;
    count: number;
    updateCartItem: (count: number) => void;
    showQuantityEdit?: boolean;
    error?: string;
    min?: number;
    max?: number;
}

const Quantity: FC<QuantityProps> = ({ counterClassName, count, updateCartItem, showQuantityEdit, error, min = 1, max = 1000 }) => {
    const handleQuantityChangeClick = (newQuantity: number) => () => {
        updateCartItem(newQuantity);
    };
    const decrementDisabled = count <= min;
    const incrementDisabled = count >= max;

    return (
        <>
            {/* Quantity Display */}
            <div className="mt-6">
                <p className="text-md">
                    <span> Qty: </span> <span className="font-bold">{count}</span>
                </p>
            </div>
            {/* Quantity Edit */}
            {showQuantityEdit && (
                <div className="mt-6">
                    <div className={counterClassName}>
                        <button
                            disabled={decrementDisabled}
                            onClick={handleQuantityChangeClick(count - 1)}
                            className={`h-30 w-30  cursor-[inherit] `}
                        >
                            -
                        </button>
                        <div className="w-60">
                            <Input
                                type="number"
                                className="!h-30 w-full rounded-none border-x border-y-0 border-input-border text-center text-md"
                                min={min}
                                max={max}
                                value={count}
                                onChange={e => handleQuantityChangeClick(parseInt(e.target.value, 10))()}
                                onBlur={e => handleQuantityChangeClick(parseInt(e.target.value, 10))()}
                            />
                        </div>
                        <button
                            disabled={incrementDisabled}
                            onClick={handleQuantityChangeClick(count + 1)}
                            className={`h-30 w-30 cursor-[inherit]`}
                        >
                            +
                        </button>
                    </div>
                </div>
            )}

            {/* Errors */}
            {error && (
                <Typography className="mt-12 text-sm leading-tight text-input-error" as="p">
                    {error}
                </Typography>
            )}
        </>
    );
};

export default Quantity;

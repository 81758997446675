export enum BundleMode {
    COMMENCEMENT_DATE = 'COMMENCEMENT_DATE',
    SELECT_COMPONENTS = 'SELECT_COMPONENTS',
    SELECT_GROUP = 'SELECT_GROUP'
}

export type EventRef = {
    productId: string;
    productSku: string;
};

export type EventGroup = {
    group: string;
    hasOosEvents: boolean;
    events: EventRef[];
};

export type EventCohort = {
    cohort: string;
    groups: EventGroup[];
};

export type AllGroupsCohort = {
    cohort: string;
    hasOosEvents: boolean;
    events: EventRef[];
};

'use client';

import { useEffect, useRef } from 'react';
import Script from 'next/script';
import type { TrustpilotSettings } from './types';

declare global {
    interface Window {
        Trustpilot: any;
    }
}
interface TrustpilotProps {
    trustpilot: TrustpilotSettings;
}

const TrustpilotWidget = ({ trustpilot }: TrustpilotProps) => {
    const theme = trustpilot.theme || 'dark';

    const ref = useRef(null);

    useEffect(() => {
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, []);

    return !trustpilot?.enabled || !trustpilot.businessunit || !trustpilot.reviewLink ? (
        <div className="w-160"></div>
    ) : (
        <div className="w-160">
            <Script src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" strategy="lazyOnload" />
            <div
                ref={ref}
                className="trustpilot-widget"
                data-locale="en-GB"
                data-template-id="53aa8807dec7e10d38f59f32"
                data-businessunit-id={trustpilot.businessunit}
                data-style-height="130px"
                data-style-width="100%"
                data-theme={theme}
            >
                <a href={trustpilot.reviewLink} target="_blank" rel="noopener noreferrer">
                    Trustpilot
                </a>
            </div>
        </div>
    );
};
export default TrustpilotWidget;

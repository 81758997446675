import { FieldType } from '@wilm/shared-types/validation-rules/types';
import type { FieldError, Fields, StringFieldDefinition } from '@wilm/shared-types/validation-rules/types';

export interface PaymentFields extends Fields {
    poNumber: StringFieldDefinition;
}

export interface CardFields extends Fields {
    cardHolderName: StringFieldDefinition;
    expiryMonth: StringFieldDefinition;
    expiryYear: StringFieldDefinition;
}

export const paymentFields: PaymentFields = {
    poNumber: {
        name: 'poNumber',
        type: FieldType.STRING,
        value: '',
        validation: {
            required: true,
            minLength: 2,
            maxLength: 50,
            errorMessages: {
                validation: 'error.missing.poNumber',
                range: 'error.range.poNumber'
            }
        }
    }
};

export const cardFields: CardFields = {
    cardHolderName: {
        name: 'cardHolderName',
        type: FieldType.STRING,
        value: '',
        validation: {
            required: true,
            minLength: 2,
            maxLength: 50,
            validate(fields) {
                const stringField: StringFieldDefinition = fields.cardHolderName as StringFieldDefinition;
                const fieldError: FieldError =
                    !/^[a-z ,.'-]+$/i.exec(stringField.value) || stringField.value.trim().split(' ').length < 2
                        ? { showError: true, message: 'error.validation.cardHolderName' }
                        : {};
                return fieldError;
            },
            errorMessages: {
                validation: 'error.missing.cardHolderName',
                range: 'error.range.cardHolderName',
                regex: 'error.validation.cardHolderName'
            }
        }
    },
    expiryMonth: {
        name: 'expiryMonth',
        type: FieldType.STRING,
        value: '',
        validation: {
            required: true,
            minLength: 2,
            maxLength: 2,
            validate(fields) {
                const fullYear = new Date().getFullYear();
                const yearIsCurrent = +fields.expiryYear.value === fullYear;
                const monthIsPast = +fields.expiryMonth.value < new Date().getMonth() + 1;
                const notValidMonth = +fields.expiryMonth.value < 1 || +fields.expiryMonth.value > 12;

                const fieldError: FieldError =
                    notValidMonth || (yearIsCurrent && monthIsPast) ? { showError: true, message: 'error.range.expiryMonth' } : {};
                return fieldError;
            },
            errorMessages: {
                validation: 'error.missing.expiryMonth',
                range: 'error.range.expiryMonth'
            }
        }
    },
    expiryYear: {
        name: 'expiryYear',
        type: FieldType.STRING,
        value: '',
        validation: {
            required: true,
            minLength: 4,
            maxLength: 4,
            validate(fields) {
                const fullYear = new Date().getFullYear();
                const yearIsPast = +fields.expiryYear.value < fullYear;
                const yearIsCurrent = +fields.expiryYear.value === fullYear;
                const yearIsAfterTenYears = +fields.expiryYear.value > fullYear + 10;
                const monthIsPast = +fields.expiryMonth.value < new Date().getMonth() + 1;

                const fieldError: FieldError =
                    yearIsPast || yearIsAfterTenYears || (yearIsCurrent && monthIsPast)
                        ? { showError: true, message: 'error.range.expiryYear' }
                        : {};
                return fieldError;
            },
            errorMessages: {
                validation: 'error.missing.expiryYear',
                range: 'error.range.expiryYear'
            }
        }
    }
};

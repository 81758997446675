import type { FC } from 'react';
import React from 'react';
import { FieldType, type Field, type FieldErrors, type Fields } from '@wilm/shared-types/validation-rules/types';
import Input from 'components/commercetools-ui/atoms/input';
import { useFormat } from 'helpers/hooks/useFormat';

export interface Props {
    fields: Fields;
    fieldErrors: FieldErrors;
    handleFieldChange: (field: Field, value: string) => void;
}

const DelegateFields: FC<Props> = ({ fields, fieldErrors, handleFieldChange }) => {
    const { formatMessage: formatFieldLabelsMessage } = useFormat({ name: 'field-labels' });
    const { formatMessage: formatFieldErrorsMessage } = useFormat({ name: 'field-errors' });

    return (
        <div className="grid grid-cols-3 gap-8">
            {Object.values(fields).map(field => {
                // If the field is hidden, don't render it
                if (field?.showOnPredicate?.(fields) === false) {
                    return null;
                }

                // Check if the field is required
                const isRequired = field.validation.required ?? field.validation.requiredPredicate?.(fields) ?? false;

                const errorMessage = fieldErrors?.[field.name]?.message;

                const formattedErrorMessage = errorMessage
                    ? formatFieldErrorsMessage({
                          id: errorMessage,
                          defaultMessage: errorMessage
                      })
                    : '';

                // Render the field based on its type
                if (field.type === FieldType.STRING) {
                    return (
                        <div key={field.name}>
                            <Input
                                id={field.name}
                                name={field.name}
                                type="text"
                                label={formatFieldLabelsMessage({
                                    id: `address.${field.name}.label`,
                                    defaultMessage: field.name
                                })}
                                value={field.value}
                                minLength={field.validation.minLength}
                                maxLength={field.validation.maxLength}
                                required={isRequired}
                                disabled={field.disabled}
                                onChange={e => {
                                    handleFieldChange(field, e.target.value);
                                }}
                                errorMessage={formattedErrorMessage}
                            />
                        </div>
                    );
                }
            })}
        </div>
    );
};

export default DelegateFields;

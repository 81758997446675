import type { FC } from 'react';
import React, { useContext, createContext } from 'react';
import type { CurrencyMapperThreshold } from '@wilm/shared-types/base/Settings';

interface CurrencyMapperThresholdProviderProps {
    currencyMapperThreshold: CurrencyMapperThreshold;
    children: React.ReactNode;
}

const CurrencyMapperThresholdContext = createContext<CurrencyMapperThreshold>({} as CurrencyMapperThreshold);

const CurrencyMapperThresholdProvider: FC<CurrencyMapperThresholdProviderProps> = ({ currencyMapperThreshold, children }) => {
    return <CurrencyMapperThresholdContext.Provider value={currencyMapperThreshold}>{children}</CurrencyMapperThresholdContext.Provider>;
};

export default CurrencyMapperThresholdProvider;

export const useCurrencyMapperThresholdContext = () => useContext(CurrencyMapperThresholdContext);

import { useState } from 'react';
import { getScrollbarWidth } from 'helpers/utils/getScrollbarWidth';
import useTouchDevice from './useTouchDevice';

const useScrollBlock = () => {
    const [isBlocked, setIsBlocked] = useState<boolean>(false);
    const { isTouchDevice } = useTouchDevice();

    const blockScrolling = () => {
        const scrollbarWidth = getScrollbarWidth();
        const scrollPosition = window.scrollY * -1;

        const header = document.querySelector<HTMLElement>('#header-container')!;

        if (!isTouchDevice) {
            document.body.style.paddingRight = `${scrollbarWidth}px`;

            if (header) header.style.paddingRight = `${scrollbarWidth}px`;
        }

        document.body.style.top = `${scrollPosition}px`;
        document.body.style.position = 'fixed';
    };

    const allowScrolling = () => {
        const header = document.querySelector<HTMLElement>('#header-container')!;
        const offset = parseInt(document.body.style.top);

        document.body.style.position = 'static';
        window.scrollTo(0, offset * -1);

        if (!isTouchDevice) {
            document.body.style.paddingRight = '0px';

            if (header) header.style.paddingRight = '0px';
        }
    };

    const blockScroll = (status: boolean) => {
        if (status) blockScrolling();
        else allowScrolling();

        setIsBlocked(status);
    };

    return { isBlocked, blockScroll };
};

export default useScrollBlock;

import React, { useMemo } from 'react';
import type { Order } from '@wilm/shared-types/cart';
import type { FC } from 'react';
import Price from 'components/sales-link/organisms/content/products/price';
import { desktop } from 'helpers/utils/screensizes';
import Skeleton from 'react-loading-skeleton';
import SummaryAccordion from 'components/commercetools-ui/organisms/order-summary/components/summary-accordion';
import Typography from 'components/commercetools-ui/atoms/typography';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import useClassNames from 'helpers/hooks/useClassNames';
import OrderItemsListing from 'components/commercetools-ui/organisms/order-items-listing';

export interface Props {
    order: Order;
    title: string;
    isThanksPage: boolean;
}

const OrderSummary: FC<any> = ({ className, asSkeleton, order, title, isThanksPage, ...props }) => {
    const itemsListClassName = useClassNames([props.classNames?.itemsList]);
    const lineItems = order.lineItems;
    const [isDesktopSize] = useMediaQuery(desktop);

    const tax = order.taxed.amount.centAmount;

    const total = useMemo(() => {
        return { centAmount: tax + order.subtotal.centAmount, currencyCode: order.subtotal.currencyCode, fractionDigits: 2 };
    }, [order]);

    if (asSkeleton) {
        return (
            <div className={className}>
                <div className="border-checkout-border mb-16 py-16 lg:border-b lg:pt-0">
                    <Skeleton className="h-30 w-full" />
                </div>
                <div className="border-checkout-border mb-16 py-16 lg:border-b lg:pt-0">
                    <Skeleton className="h-80 w-full" />
                </div>
                <Skeleton className="h-30" />
            </div>
        );
    }

    return (
        <div className={className}>
            <div className="border-checkout-border py-16 lg:border-b lg:pt-0">
                {title && (
                    <Typography className="text-16 md:text-18" as="h4">
                        {title}
                    </Typography>
                )}
            </div>

            {isDesktopSize && lineItems && (
                <OrderItemsListing className={itemsListClassName} lineItems={lineItems} isThanksPage={isThanksPage} />
            )}
            {!isDesktopSize && <SummaryAccordion className="lg:mt-40" order={order} />}

            <div className="my-20 lg:mb-0">
                <div className="mb-6 flex w-full items-center justify-between text-md capitalize lg:text-base">
                    <span>Subtotal:</span> <Price price={order.subtotal} />
                </div>
                <div className="mb-6 flex w-full items-center justify-between text-md capitalize lg:text-base">
                    <span>Tax:</span> <Price price={order.taxed.amount} />
                </div>
                <div className="flex items-center justify-between pt-12 text-18 text-18 md:pb-20">
                    <span className="font-bold">Total:</span>
                    <span className="font-bold">
                        <Price price={total} />
                    </span>
                </div>
            </div>

            {props.button}
        </div>
    );
};

export default OrderSummary;

import { FieldType } from '@wilm/shared-types/validation-rules/types';
import type { DateFieldDefinition, EnumFieldDefinition, Fields, NumberFieldDefinition } from '@wilm/shared-types/validation-rules/types';
import { TimezoneNameEnum } from '@wilmingtongroup/common-validation-rules';

export interface InHouseFields extends Fields {
    startDate: DateFieldDefinition;
    timeZone: EnumFieldDefinition;
    capacity: NumberFieldDefinition;
}

export interface TimeZoneOptions {
    label: string;
    value: string;
}

let timeZoneOptions: TimeZoneOptions[] = Object.values(TimezoneNameEnum).map(timezone => ({ label: timezone, value: timezone }));
timeZoneOptions = [{ label: '-- Select --', value: '' }, ...timeZoneOptions];

export const inHouseFieldsDefinitions: InHouseFields = {
    startDate: {
        type: FieldType.DATE,
        label: 'Start date',
        name: 'start-date',
        value: '',
        validation: {
            required: true,
            errorMessages: {
                validation: 'error.missing.startDate'
            }
        }
    },
    timeZone: {
        type: FieldType.ENUM,
        label: 'Timezone',
        name: 'timezone',
        value: '',
        options: timeZoneOptions,
        validation: {
            required: true,
            errorMessages: {
                validation: 'error.missing.timezone'
            },
            validate(fields: Fields) {
                if (!Object.values(TimezoneNameEnum).includes(fields.timeZone.value as TimezoneNameEnum)) {
                    return { showError: true, message: 'error.validation.timezone' };
                }
                return {};
            }
        }
    },
    capacity: {
        type: FieldType.NUMBER,
        label: 'Capacity',
        name: 'capacity',
        value: 1,
        validation: {
            min: 1,
            max: 999,
            regex: /^[1-9]\d*$/, // regex for whole numbers only
            required: true,
            errorMessages: {
                validation: 'error.missing.capacity',
                range: 'error.validation.capacity.range',
                regex: 'error.validation.capacity.regex'
            }
        }
    }
};

import type { FC } from 'react';
import type { LineItem } from '@wilm/shared-types/cart/LineItem';
import useClassNames from 'helpers/hooks/useClassNames';
import useBundle from 'frontastic/hooks/useBundle';
import OrderItem from './order-item';

type OrderItemsListProps = {
    className?: string;
    lineItems: LineItem[];
};

const OrderItemsList: FC<OrderItemsListProps> = ({ lineItems, className }) => {
    const containerClassName = useClassNames(['grid w-full grid-cols-1 overflow-auto', className]);

    const { bundleComponents } = useBundle(lineItems);

    return (
        <div className={containerClassName}>
            {lineItems.map(lineItem => (
                <OrderItem key={lineItem.lineItemId} lineItem={lineItem} bundleItems={bundleComponents?.[lineItem.lineItemId]} />
            ))}
        </div>
    );
};

export default OrderItemsList;

import type { FC } from 'react';
import type { CheckboxProps } from 'components/commercetools-ui/atoms/checkbox';
import Checkbox from 'components/commercetools-ui/atoms/checkbox';
import Markdown from 'components/commercetools-ui/organisms/markdown';

export interface TermsAndConditionsProps {
    termsAndConditions: TermsAndConditionsType;
    handleTermsChange: CheckboxProps['onChange'];
}

export type TermsAndConditionsType = Record<
    string,
    {
        text: string;
        checked: boolean;
    }
>;

const TermsAndConditions: FC<TermsAndConditionsProps> = ({ termsAndConditions, handleTermsChange }) => {
    return (
        <>
            {Object.keys(termsAndConditions).map(key => {
                return (
                    <div key={key} className="mb-16">
                        <Checkbox
                            name={key}
                            checked={termsAndConditions[key].checked}
                            onChange={handleTermsChange}
                            containerClassName="-mt-1"
                            label={<Markdown className="text-md" markdown={termsAndConditions[key].text} />}
                        />
                    </div>
                );
            })}
        </>
    );
};

export default TermsAndConditions;

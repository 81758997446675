'use client';
import React, { useMemo } from 'react';
import NextLink from 'next/link';
import useClassNames from 'helpers/hooks/useClassNames';
import { resolveReferenceProps, resolveReferenceTarget } from 'helpers/reference';
import type { Reference } from 'types/reference';

export type LinkVariant = 'primary' | 'menu-header' | 'menu-item' | 'breadcrumb' | 'secondary' | 'footer-item';

export interface LinkProps extends Omit<React.ComponentProps<typeof NextLink>, 'href' | 'key'> {
    link?: string | Reference;
    variant?: LinkVariant;
}

type VariantStyle = { [key in LinkVariant]: string };

const variantStyle: VariantStyle = {
    primary: 'text-md cursor-pointer text-primary-black underline decoration-solid',
    secondary: 'text-md cursor-pointer text-primary-black underline decoration-solid font-bold',
    'menu-item':
        'font-menu-weight font-menu text-menu-subcat-size text-menu-subcat md:text-menu-subcat-size-md hover:underline hover:underline-offset-2',
    'footer-item': 'text-11 md:text-md cursor-pointer text-footer-text hover:underline decoration-solid',
    breadcrumb: 'text-14 font-medium text-primary-black',
    'menu-header':
        'flex  md:pr-35 w-full md:w-auto  md:mb-0 justify-between md:basis-1/2 items-center font-menu-weight font-menu text-menu-cat-size md:text-menu-cat-size-md text-menu-cat cursor-pointer hover:underline hover:underline-offset-2'
};

const Link = ({ link, children, className = '', variant, title = '', ...props }: React.PropsWithChildren<LinkProps>) => {
    const linkUrl = useMemo(() => {
        if (!link) return '';
        if (typeof link === 'string') return link;

        return resolveReferenceTarget(link);
    }, [link]);

    const href = linkUrl ?? '#';

    const linkProps = useMemo(() => {
        if (!link || typeof link === 'string') return {};

        return resolveReferenceProps(link);
    }, [link]);

    const linkClassNames = useClassNames([variant ? variantStyle[variant] : '', className]);

    return (
        <NextLink {...props} {...linkProps} href={href} className={linkClassNames} title={title}>
            {children}
        </NextLink>
    );
};

export default Link;

import type { FC } from 'react';
import Button from 'components/commercetools-ui/atoms/button';
import Link from 'components/commercetools-ui/atoms/link';
import type { CheckoutButtonProps } from '../types';

const CheckoutButton: FC<CheckoutButtonProps> = ({ className, link, disabled, text, onClick, dataCy }) => {
    return (
        <div className={className}>
            <Link link={link}>
                <Button disabled={disabled} onClick={onClick} variant="primaryCta" size="full" className="mt-16" data-cy={dataCy}>
                    {text}
                </Button>
            </Link>
        </div>
    );
};

export default CheckoutButton;

import type { Currency } from '@commercetools/frontend-sdk/lib/types/Currency';
import type { GTMTag, GTMActionPayload, TrackBeginCheckoutPayloadType } from './../interfaces/gtmTag';
import TagsActionType from './types/tagsActionType';
import getDiscountCodesString from '../utils/getDiscountCodesString';
import getGtmItems from '../utils/getGtmItems';
import getGtmValue from '../utils/getGtmValue';

const beginCheckoutHandler = ({ payload }: { payload: GTMActionPayload }): GTMTag => {
    const beginCheckoutPayload = payload as TrackBeginCheckoutPayloadType;
    const cart = beginCheckoutPayload.cart;
    const discountCodes = getDiscountCodesString(cart?.discountCodes ?? []);
    const items = getGtmItems(cart?.lineItems ?? []);
    const value = getGtmValue(cart.sum!);
    const currency = cart?.sum?.currencyCode as Currency;

    return {
        ecommerce: {
            currency: currency,
            items: items,
            value: value,
            ...(discountCodes && { coupon: discountCodes })
        },
        event: TagsActionType.BEGIN_CHECKOUT
    };
};

export default beginCheckoutHandler;

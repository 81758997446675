import type { Order } from '@wilm/shared-types/cart/Order';
import type { PaymentObject } from '@wilm/shared-types/cart/Payment';

export enum PaymentLinkStatus {
    READY_FOR_PAYMENT = 'ready_for_payment',
    TOKEN_NOT_EXIST = 'token_not_exist',
    TOKEN_EXPIRED = 'token_expired'
}

export interface PaymentLinkInfoType {
    hash: string;
    status: PaymentLinkStatus;
    createdAt?: string;
    expiresAt?: string;
    unlockId?: string;
    order?: Order;
    payment: PaymentObject;
    requestedByEmail?: string;
    customerEmail?: string;
}

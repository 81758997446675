'use client';
import React, { useEffect } from 'react';

export interface Props {
    endDateTime: string;
}

const Countdown: React.FC<Props> = ({ endDateTime }) => {
    //calculate difference between current time and end time and update it every second
    const [timeLeft, setTimeLeft] = React.useState(0);
    const [loading, setLoading] = React.useState(true);

    const calculateTimeLeft = (endDateTime: string) => {
        const endTime = new Date(endDateTime).getTime();
        const currentTime = new Date().getTime();
        return endTime - currentTime;
    };

    useEffect(() => {
        if (endDateTime) {
            setTimeLeft(calculateTimeLeft(endDateTime));
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft(endDateTime));
        }, 1000);

        return () => clearTimeout(timer);
    }, [timeLeft]);

    const hours = Math.floor(timeLeft / 3600000);
    const minutes = Math.floor(timeLeft / 60000) % 60;
    const seconds = Math.floor((timeLeft % 60000) / 1000);

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <div className="mt-8 font-bold">
            {timeLeft > 0 && (
                <p>
                    Time left: {hours ? `${hours} hours` : ''} {minutes} minutes and {seconds} seconds
                </p>
            )}

            {timeLeft <= 0 && <p>This link has expired</p>}
        </div>
    );
};

export default Countdown;

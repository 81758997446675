import type { Currency } from '@commercetools/frontend-sdk/lib/types/Currency';
import type { GTMTag, GTMActionPayload, TrackAddShippingInfoPayloadType } from './../interfaces/gtmTag';
import TagsActionType from './types/tagsActionType';
import getDiscountCodesString from '../utils/getDiscountCodesString';
import getGtmItems from '../utils/getGtmItems';
import getGtmValue from '../utils/getGtmValue';

const addShippingInfoHandler = ({ payload }: { payload: GTMActionPayload }): GTMTag => {
    const addShippingInfoPayload = payload as TrackAddShippingInfoPayloadType;
    const addShippingInfoCart = addShippingInfoPayload.cart;
    const addShippingInfoDiscountCodes = getDiscountCodesString(addShippingInfoCart?.discountCodes ?? []);
    const addShippingInfoItems = getGtmItems(addShippingInfoCart?.lineItems ?? []);
    const addShippingInfoValue = getGtmValue(addShippingInfoCart.sum!);
    const addShippingInfoCurrency = addShippingInfoCart?.sum?.currencyCode as Currency;
    const shippingMethod = addShippingInfoPayload.shippingMethod;

    return {
        ecommerce: {
            currency: addShippingInfoCurrency,
            items: addShippingInfoItems,
            value: addShippingInfoValue,
            shipping_tier: shippingMethod,
            ...(addShippingInfoDiscountCodes && { coupon: addShippingInfoDiscountCodes })
        },
        event: TagsActionType.ADD_SHIPPING_INFO
    };
};

export default addShippingInfoHandler;

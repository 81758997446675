import type { FC } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import type { Money } from '@wilm/shared-types/product/Money';
import Typography from 'components/commercetools-ui/atoms/typography';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import useClassNames from 'helpers/hooks/useClassNames';
import { useFormat } from 'helpers/hooks/useFormat';

type AccordionButtonProps = {
    open: boolean;
    total: Required<Money>;
};

const AccordionButton: FC<AccordionButtonProps> = ({ open, total }) => {
    const { formatMessage: formatOrdersMessage } = useFormat({ name: 'orders' });

    const accordionContentClassNames = useClassNames(['flex w-full justify-between border-t py-16', open ? 'border-t' : '']);

    const arrowClassNames = useClassNames([open ? 'rotate-180 transform' : '', 'w-24 transition']);

    return (
        <div className={accordionContentClassNames}>
            <Typography className="font-normal text-secondary-black lg:font-bold">
                {formatOrdersMessage({
                    id: 'your.order',
                    defaultMessage: 'Your Order'
                })}
            </Typography>
            <div className="flex">
                <Typography className="hidden pr-8 font-medium text-primary-black md:block">
                    {CurrencyHelpers.formatForCurrency(total)}
                </Typography>
                <ChevronDownIcon width={20} strokeWidth={1} className={arrowClassNames} />
            </div>
        </div>
    );
};

export default AccordionButton;

'use client';

import React from 'react';
import type { HeroProps } from 'components/commercetools-ui/organisms/content/hero';
import Hero from 'components/commercetools-ui/organisms/content/hero';
import type { TasticProps } from 'frontastic/tastics/types';

const HeroTastic = ({ data }: TasticProps<HeroProps>) => {
    return (
        <Hero image={data.image} title={data.title} subtitle={data.subtitle} ctaLabel={data.ctaLabel} ctaReference={data.ctaReference} />
    );
};

export default HeroTastic;

import type { FC } from 'react';
import React from 'react';
import Typography from 'components/commercetools-ui/atoms/typography';
import useClassNames from 'helpers/hooks/useClassNames';
import { useFormat } from 'helpers/hooks/useFormat';
import Costs from './components/costs';
import DiscountForm from './components/discount-form';
import PaymentMethods from './components/payment-methods';
import type { OrderSummaryProps } from '../order-summary/types';

const OrderPaymentSection: FC<OrderSummaryProps> = ({
    className,
    paymentMethods = [],
    button,
    dataReference = 'cart',
    classNames,
    isMiniCart,
    isCartPage,
    order,
    isEmpty,
    loading,
    hasOutOfStockItems,
    ...props
}) => {
    const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

    const infoContainerClassName = useClassNames(['border-checkout-border border-t bg-white', classNames?.infoContainer]);

    return (
        <div className={className} {...props}>
            {!isEmpty && !order && <DiscountForm className={classNames?.applyDiscountButton} />}

            <div className={infoContainerClassName}>
                {!isMiniCart && (
                    <Costs
                        order={order}
                        dataReference={dataReference}
                        totalAmountClassName={classNames?.totalAmount}
                        subCostsContainerClassName={classNames?.subCostsContainer}
                        subCostClassName={classNames?.subCost}
                        isCartPage={isCartPage}
                        loading={loading}
                    />
                )}
                {!isEmpty && isMiniCart && (
                    <p className="text-md">
                        {formatCartMessage({ id: 'totals.disclaimer', defaultMessage: 'Discounts and tax will be applied at checkout' })}
                    </p>
                )}
                {button}
                {hasOutOfStockItems && (
                    <Typography className="mt-8 text-14 text-red-500">
                        {formatCartMessage({ id: 'cart.outOfStock', defaultMessage: 'Some items are out of stock' })}
                    </Typography>
                )}
                {!isEmpty && isCartPage && (
                    <p className="mt-15 hidden text-md md:block">
                        {formatCartMessage({
                            id: 'tax.disclaimer',
                            defaultMessage: 'The tax amount will be calculated on the Payment step of the Checkout'
                        })}
                    </p>
                )}
                {paymentMethods.length > 0 && <PaymentMethods paymentMethods={paymentMethods} />}
            </div>
        </div>
    );
};

export default OrderPaymentSection;

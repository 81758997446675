import React, { useCallback, useMemo, useState } from 'react';
import type { Variant } from '@commercetools/frontend-domain-types/product';
import { BundleMode } from '@wilm/shared-types/product/Bundle';
import { ProductTypeKey, type Product } from '@wilm/shared-types/product/Product';
import type { LineItemAddToCartAttributes, BundleLineItemError } from '@wilm/shared-types/sales-link/SalesLinkCart';
import { validateSelectedBundleComponent } from '@wilm/shared-types/validation-rules/sales-link/cart';
import Button from 'components/commercetools-ui/atoms/button';
import Modal from 'components/commercetools-ui/atoms/modal';
import BundleComponentSelector from 'components/sales-link/organisms/content/products/bundle-component-selector';
import { useFormat } from 'helpers/hooks/useFormat';
import { sortVariantsByStartDate } from 'helpers/productHelpers';
import { useBrandSettingsContext } from 'providers/brand-settings';
import { useSalesLinkCartContext } from 'providers/sales-link/cart';
import { useSalesLinkDataContext } from 'providers/sales-link/data';
import useSalesLinkProduct from 'frontastic/hooks/useSalesLinkProduct';
import type { SalesLinkAttribute } from 'frontastic/hooks/useSalesLinkProduct';
import CreateInHouse from '../create-in-house';

export interface Props {
    product: Product;
    loading?: boolean;
}

const ProductTile: React.FC<Props> = ({ product, loading }) => {
    const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
    const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
    const { bundleMode } = useBrandSettingsContext();
    const { selectedCurrency, isAddedToCart, addToCart: addToCartContext, isCartLoading } = useSalesLinkCartContext();
    const { getProductAttributesToShow, getAdditionalProductVariantInfo, getBundleComponentIds } = useSalesLinkProduct();
    const { query, getBundleOptions, salesLinkSettings } = useSalesLinkDataContext();
    const [isMoreDetailsModalOpen, setIsMoreDetailsModalOpen] = useState(false);
    const [isBundleSelectorModalOpen, setIsBundleSelectorModalOpen] = useState(false);
    const [modalInfo, setModalInfo] = useState<{ label: string; value: string | JSX.Element }[]>([]);
    const [selectedBundle, setSelectedBundle] = useState<Variant>({} as Variant);
    const [bundleErrors, setBundleErrors] = useState<BundleLineItemError>({ error: { code: '' }, missingSelectedComponentIds: [] });
    const [bundleOptions, setBundleOptions] = useState<{ label: string; value: string }[]>([]);

    const addToCart = useCallback(
        (product: Product, variant: Variant, attributes?: LineItemAddToCartAttributes) => {
            void addToCartContext(product, variant, attributes);
        },
        [addToCartContext]
    );

    const handleAddToCart = useCallback(
        async (product: Product, variant: Variant, attributes?: LineItemAddToCartAttributes) => {
            if (product.productTypeKey === ProductTypeKey.BUNDLE) {
                if (bundleMode === BundleMode.SELECT_GROUP) {
                    const bundleOptions = await getBundleOptions(variant.sku, variant.id!);
                    setBundleOptions(bundleOptions);
                }
                setSelectedBundle(variant);
                setIsBundleSelectorModalOpen(true);
                return;
            }
            addToCart(product, variant, attributes);
        },
        [addToCart]
    );

    const handleBundleAddToCart = useCallback(
        (variant: Variant, attributes?: LineItemAddToCartAttributes) => {
            const bundleErrors = validateSelectedBundleComponent(
                getBundleComponentIds(selectedBundle),
                attributes?.selectedBundleComponentVariantSKUs as Record<string, string>,
                attributes?.selectedCommencementDate as string,
                bundleMode
            );

            if (bundleErrors.error.code) {
                setBundleErrors(bundleErrors);
                return;
            }
            setBundleErrors({ error: { code: '' }, missingSelectedComponentIds: [] });
            addToCart(product, variant, attributes);
            closeBundleSelectorModal();
        },
        [addToCart, getBundleComponentIds, product, selectedBundle, bundleMode]
    );

    const closeBundleSelectorModal = useCallback(() => setIsBundleSelectorModalOpen(false), []);

    const handleMoreDetails = useCallback(
        (product: Product, variant: Variant) => {
            setIsMoreDetailsModalOpen(true);
            const moreInfo = getAdditionalProductVariantInfo(product, variant);
            setModalInfo(moreInfo);
        },
        [getAdditionalProductVariantInfo]
    );

    const closeMoreDetailsModal = useCallback(() => setIsMoreDetailsModalOpen(false), []);

    const name = product.name;
    const key = product.key;

    const formattedVariants = useMemo(() => {
        const variants = product.productTypeKey === ProductTypeKey.TRAINING ? sortVariantsByStartDate(product.variants) : product.variants;
        return variants?.map(variant => {
            const alreadyAdded = isAddedToCart(variant.sku);
            const outOfStock = variant.availableQuantity! <= 0 && !variant.attributes?.isInventoryPerpetual;
            const hasPriceInSelectedCurrency = !!variant.prices?.[selectedCurrency];
            let addToCartButtonText = formatProductMessage({ id: 'cart.add', defaultMessage: 'Add to cart' });
            if (alreadyAdded) {
                addToCartButtonText = formatProductMessage({ id: 'cart.added', defaultMessage: 'Added to cart' });
            }
            if (outOfStock) {
                addToCartButtonText = formatProductMessage({ id: 'outOfStock', defaultMessage: 'Out of stock' });
            }
            if (!hasPriceInSelectedCurrency) {
                addToCartButtonText = formatProductMessage({ id: 'not.available', defaultMessage: 'Not available' });
            }
            const addToCartDisabled = alreadyAdded || outOfStock || !hasPriceInSelectedCurrency || isCartLoading;

            const actions: SalesLinkAttribute = {
                label: formatCommonMessage({ id: 'actions', defaultMessage: 'Actions' }),
                value: (
                    <div className="mt-6 flex flex-col gap-4 sm:flex-row sm:gap-0 xl:justify-center">
                        <Button
                            className="mr-5 w-full md:w-1/2"
                            variant="secondary"
                            size="xs"
                            onClick={() => handleMoreDetails(product, variant)}
                        >
                            {formatCommonMessage({ id: 'more.details', defaultMessage: 'More details' })}
                        </Button>
                        <Button
                            className="w-full md:w-1/2"
                            variant="primary"
                            size="xs"
                            onClick={() => handleAddToCart(product, variant)}
                            disabled={addToCartDisabled}
                            loading={!alreadyAdded && !outOfStock && hasPriceInSelectedCurrency && isCartLoading}
                        >
                            {addToCartButtonText}
                        </Button>
                    </div>
                ),
                key: 'actions'
            };
            return getProductAttributesToShow(product.productTypeKey, variant, query.currency).concat(actions);
        });
    }, [product, getProductAttributesToShow, addToCart, handleMoreDetails, isAddedToCart, isCartLoading]);

    // if loading show skeleton
    if (loading) {
        return (
            <div>
                <p className="bg-checkout-bg p-12 font-bold">Searching...</p>
                <div className="mb-16 grid-cols-2 gap-8 border-t px-16 xl:mb-0 xl:grid xl:grid-cols-5">
                    {
                        // loop 5 times
                        Array.from({ length: 5 }).map((_, index) => (
                            <div key={'variant-' + index} className="mb-3 grid grid-cols-2 xl:block ">
                                <p className={` text-center font-bold`}>...</p>
                                <div className="ml-8 overflow-hidden xl:ml-0 xl:max-h-48"></div>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="mb-16">
                <div className="flex justify-between bg-gray-200 p-10">
                    <span className="my-auto font-bold">
                        {name} {key}
                    </span>
                    {product.productTypeKey === ProductTypeKey.TRAINING && salesLinkSettings.createInHouseEventsEnabled && (
                        <CreateInHouse product={product} />
                    )}
                </div>
                {formattedVariants.map((variant, variantIndex) => (
                    <div key={'variant-' + variantIndex} className="mx-16 mb-16 grid-cols-2 gap-4 border-t xl:mb-0 xl:grid xl:grid-cols-5">
                        {variant.map((attribute, attributeIndex) => (
                            <div key={'attribute-' + attributeIndex} className="mb-3 grid grid-cols-2 text-md xl:block">
                                <p className={`bg-neutral-200 px-8 py-10 text-md font-bold ${variantIndex === 0 ? '' : 'xl:hidden'}`}>
                                    {attribute.label}
                                </p>
                                <div className="ml-8 overflow-hidden px-6 py-8 xl:ml-0">{attribute.value}</div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>

            <Modal
                isOpen={isMoreDetailsModalOpen}
                className="relative w-[90%] overflow-hidden rounded-md bg-white focus-visible:outline-none"
                preventScroll={true}
                style={{ content: { maxWidth: '800px' } }}
                closeTimeoutMS={200}
                onRequestClose={closeMoreDetailsModal}
                closeButton
            >
                <div className="max-h-screen overflow-auto p-40">
                    <h2 className="mb-20 text-center text-20">
                        {formatCommonMessage({ id: 'more.details', defaultMessage: 'More details' })}
                    </h2>
                    {modalInfo.map((info, index) => (
                        <div key={`${info.label}-${index}`} className="grid grid-cols-6 gap-10 pb-6 text-md leading-loose">
                            <p className="col-span-2 break-words font-bold">{info.label}</p>
                            <p className="col-span-4">{info.value}</p>
                        </div>
                    ))}

                    <div className="mt-20 flex justify-end">
                        <Button variant="secondary" onClick={closeMoreDetailsModal}>
                            {formatCommonMessage({ id: 'close', defaultMessage: 'Close' })}
                        </Button>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={isBundleSelectorModalOpen}
                className={`relative w-[90%] rounded-md bg-white focus-visible:outline-none ${bundleMode !== BundleMode.SELECT_GROUP ? 'overflow-hidden' : ''}`}
                preventScroll={true}
                style={{ content: { maxWidth: '1920px' } }}
                closeTimeoutMS={200}
                onRequestClose={closeBundleSelectorModal}
                closeButton
            >
                <BundleComponentSelector
                    mode={bundleMode}
                    bundleOptions={bundleOptions}
                    variant={selectedBundle}
                    bundleErrors={bundleErrors}
                    onAddToCart={handleBundleAddToCart}
                />
            </Modal>
        </>
    );
};

export default ProductTile;

import React from 'react';
import type { FC } from 'react';
import Button from 'components/commercetools-ui/atoms/button';
import Modal from 'components/commercetools-ui/atoms/modal';
import Markdown from 'components/commercetools-ui/organisms/markdown';

export interface MessageModalProps {
    isOpen: boolean;
    closeModal: () => void;
    data: {
        message: string;
        type: 'error' | 'info';
        heading?: string;
        showCloseButton?: boolean;
        closeButtonText?: string;
        showCTAButton?: boolean;
        ctaButtonText?: string;
        ctaButtonVariant?: 'primary' | 'secondary' | 'warning';
        ctaButtonAction?: () => void;
    };
}

const MessageModal: FC<MessageModalProps> = ({ isOpen, closeModal, data }) => {
    return (
        <Modal
            isOpen={isOpen}
            className="relative w-[90%] rounded-md bg-white"
            style={{ content: { maxWidth: '600px' } }}
            preventScroll={true}
        >
            <div className="flex flex-col gap-25 p-15 text-base leading-6 md:gap-20 md:p-40">
                {data.heading && <h2 className="text-center text-lg font-bold">{data.heading}</h2>}
                <Markdown className="text-center" markdown={data.message} />

                {data.showCloseButton && data.closeButtonText && (
                    <Button
                        variant={data.type === 'error' ? 'warning' : 'secondary'}
                        size="full"
                        onClick={closeModal}
                        className="rounded-md md:rounded-lg"
                    >
                        {data.closeButtonText}
                    </Button>
                )}

                {data.showCTAButton && data.ctaButtonText && data.ctaButtonAction && (
                    <Button
                        variant={data.ctaButtonVariant ?? 'primary'}
                        size="full"
                        onClick={data.ctaButtonAction}
                        className="rounded-md md:rounded-lg"
                    >
                        {data.ctaButtonText}
                    </Button>
                )}
            </div>
        </Modal>
    );
};

export default MessageModal;

export type Attributes = Record<string, any>;

export enum AttributeEnum {
    SHORT_DESCRIPTION = 'shortDescription',
    START_DATE = 'startDate',
    END_DATE = 'endDate',
    ADMINISTRATE_EVENT_COHORT = 'cohort',
    SKU = 'ct.variant.sku',
    LEARNING_MODE = 'learningMode',
    EVENT_TYPE = 'eventType',
    PRICE = 'price'
}
export interface Attribute {
    label: string;
    value: string;
}

import React from 'react';
import { Popover } from '@headlessui/react';
import Link from 'components/commercetools-ui/atoms/link';
import AccountDropdown from 'components/commercetools-ui/organisms/account/account-atoms/account-dropdown';
import AccountIcon from 'components/icons/profile';
import { useFormat } from 'helpers/hooks/useFormat';
import type { Reference } from 'types/reference';
import { useAccount } from 'frontastic';

type Props = {
    accountLabel?: string;
    accountLabelLogged?: string;
    accountLinkLabel?: string;
    accountLink?: Reference;
    accountLogoutLabel?: string;
    wishlistLinkLabel?: string;
    wishlistLink?: Reference;
    resetFlag: () => void;
};

const AccountButton: React.FC<Props> = ({
    accountLabelLogged,
    accountLabel,
    accountLinkLabel,
    accountLink,
    accountLogoutLabel,
    wishlistLinkLabel,
    wishlistLink,
    resetFlag
}) => {
    const { account, loggedIn } = useAccount();
    const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

    const title = account
        ? formatAccountMessage({ id: 'account', defaultMessage: 'Account' })
        : formatAccountMessage({ id: 'sign.in', defaultMessage: 'Login' });

    return (
        <div>
            {!loggedIn ? (
                <Link
                    link={'/login'}
                    title={title}
                    className="mx-8 flex flex-col items-center hover:text-icons-account md:mx-12"
                    onClick={resetFlag}
                >
                    <p className="flex h-header-icons items-center md:h-header-icons-md" data-cy="login">
                        <AccountIcon className="w-24 md:w-34" />
                    </p>
                    {accountLabel && <p className="mt-5 text-icons font-icons uppercase leading-tight md:text-icons-md">{accountLabel}</p>}
                </Link>
            ) : (
                <Popover as="div" className="relative flex h-fit">
                    {() => (
                        <>
                            <Popover.Button title={title} data-cy="profile-icon">
                                <div className="flex w-fit whitespace-nowrap">
                                    <div className="mx-8 flex flex-col items-center hover:text-icons-account focus-visible:outline-0 md:mx-12">
                                        <p className="flex h-header-icons items-center md:h-header-icons-md">
                                            <AccountIcon className="w-24 md:w-34" />
                                        </p>
                                        {accountLabelLogged && (
                                            <p className="mt-5 text-icons font-icons uppercase leading-tight md:text-icons-md">
                                                {accountLabelLogged}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </Popover.Button>
                            <Popover.Overlay className="fixed inset-0 z-[310] bg-secondary-black opacity-30" />
                            <Popover.Panel className="absolute -left-56 top-80 z-[310] animate-[appearDropdown_0.15s_ease-in-out] rounded-sm bg-white shadow-400">
                                <div className="absolute -top-20 left-1/2 z-10 w-31 -translate-x-1/2 overflow-hidden">
                                    <div className="size-21 origin-bottom-left rotate-45 bg-white" />
                                </div>
                                <AccountDropdown
                                    loggedIn={loggedIn}
                                    accountLinkLabel={accountLinkLabel}
                                    accountLink={accountLink}
                                    accountLogoutLabel={accountLogoutLabel}
                                    wishlistLinkLabel={wishlistLinkLabel}
                                    wishlistLink={wishlistLink}
                                    resetFlag={resetFlag}
                                />
                            </Popover.Panel>
                        </>
                    )}
                </Popover>
            )}
        </div>
    );
};

export default AccountButton;

import React from 'react';

type Props = {
    className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
    <svg className={className} width="35" height="33" viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="13.5" cy="13.5" r="12.5" stroke="#EC6F57" strokeWidth="2" />
        <line x1="25.7071" y1="24.2929" x2="33.7071" y2="32.2929" stroke="#EC6F57" strokeWidth="2" />
    </svg>
);

export default Icon;

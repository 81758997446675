import type { FC } from 'react';
import React, { useContext, createContext, useMemo, useCallback, useState, useEffect } from 'react';
import type { SDKResponse } from '@commercetools/frontend-sdk';
import type { Product, ProductType } from '@wilm/shared-types/product/Product';
import type { SalesLinkSettings } from '@wilm/shared-types/sales-link/SalesLink';
import { sdk } from 'sdk';
import type { SWRResponse } from 'swr';
import useSWR from 'swr';
import { revalidateOptions } from 'frontastic';

interface SalesLinkDataProviderProps {
    salesLinkSettings: SalesLinkSettings;
    children: React.ReactNode;
}

interface SalesLinkDataContextShape {
    query: ProductQuery;
    setQuery: React.Dispatch<React.SetStateAction<ProductQuery>>;
    page: number;
    totalPages: number;
    totalResults: number;
    goToPage: (page: number) => void;
    currencies: string[];
    currenciesLoading: boolean;
    productTypes: ProductType[];
    productTypesLoading: boolean;
    products: Product[] | undefined;
    productsLoading: boolean;
    searchProducts: () => void;
    searchProductBySku: (sku: string) => void;
    salesLinkSettings: SalesLinkSettings;
    getBundleOptions: (productSku: string, variantId: string) => Promise<{ label: string; value: string }[]>;
}

interface ProductQuery {
    skus?: string[];
    term?: string;
    la1Id: string;
    currency: string;
    productType?: string;
    limit: number;
    offset: number;
}

const SEARCH_LIMIT = 8;

const SalesLinkDataContext = createContext<SalesLinkDataContextShape>({} as SalesLinkDataContextShape);

const SalesLinkDataProvider: FC<SalesLinkDataProviderProps> = ({ salesLinkSettings, children }) => {
    const [query, setQuery] = useState({
        term: '',
        la1Id: '',
        currency: '',
        productType: '',
        limit: SEARCH_LIMIT,
        offset: 0
    } as ProductQuery);

    const currenciesResult = useSWR('/action/project/getProjectSettings', sdk.composableCommerce.project.getSettings, revalidateOptions);

    const currenciesLoading = currenciesResult.isValidating;
    const currencies = useMemo(
        () => (currenciesResult.data?.isError ? ([] as string[]) : currenciesResult.data?.data.currencies) ?? ([] as string[]),
        [currenciesResult.data]
    );
    const productTypesResult: SWRResponse<SDKResponse<ProductType[]>> = useSWR(
        '/action/product/getProductTypes',
        () => sdk.callAction({ actionName: 'product/getProductTypes' }) as unknown as SDKResponse<ProductType[]>,
        revalidateOptions
    );

    const productTypesLoading = productTypesResult.isValidating;
    const productTypes = useMemo(
        () => (productTypesResult.data?.isError ? ([] as ProductType[]) : productTypesResult.data?.data) ?? ([] as ProductType[]),
        [productTypesResult.data]
    );

    const [productsLoading, setProductsLoading] = useState<boolean>(false);

    const [products, setProducts] = useState<Product[] | undefined>(undefined);
    const [page, setPage] = useState(1);
    const [totalResults, setTotalResults] = useState(0);
    const [totalPages, setTotalPages] = useState(1);

    const search = useCallback(async (ctQuery: ProductQuery) => {
        setProductsLoading(true);
        const productResults = (await sdk.composableCommerce.product.query(ctQuery)) as SDKResponse<{
            items: Product[];
            total: number;
        }>;

        if (!productResults.isError) {
            setProducts(productResults.data.items);
            setTotalResults(productResults.data.total);
            setTotalPages(Math.ceil(productResults.data.total / ctQuery.limit));
        } else {
            console.error('Error fetching products', productResults.error);
        }

        setProductsLoading(false);
    }, []);

    const searchProducts = useCallback(() => {
        const ctQuery = {
            query: query.term,
            currency: query.currency,
            limit: query.limit,
            offset: query.offset,
            productType: query.productType,
            la1Id: query.la1Id
        };

        void search(ctQuery);
    }, [query, search]);

    const searchProductBySku = useCallback(
        (sku: string) => {
            const ctQuery = {
                skus: [sku],
                currency: query.currency,
                la1Id: query.la1Id,
                limit: SEARCH_LIMIT,
                offset: 0
            };

            void search(ctQuery);
        },
        [query, search]
    );

    const resetProductResults = useCallback(() => {
        setProducts(undefined);
        setTotalPages(1);
        setPage(1);
        setQuery({ ...query, offset: 0 });
    }, [query]);

    useEffect(() => {
        resetProductResults();
    }, [query.currency, query.productType, query.la1Id, query.term]);

    const goToPage = useCallback(
        (page: number) => {
            setPage(page);
            query.offset = (page - 1) * query.limit;
            setQuery({ ...query });

            searchProducts();
        },
        [query, searchProducts]
    );

    const getBundleOptions = useCallback(async (productSku: string, variantId: string) => {
        const result: SDKResponse<{ label: string; value: string }[]> = await sdk.callAction({
            actionName: 'product/getBundleSelectorOptions',
            payload: {
                productSku,
                variantId
            }
        });

        if (result.isError) {
            console.error('Error fetching bundle options', result.error);
            return [];
        }

        return result.data;
    }, []);

    const value = useMemo(
        () => ({
            query,
            setQuery,
            page,
            totalPages,
            totalResults,
            goToPage,
            currencies,
            currenciesLoading,
            productTypes,
            productTypesLoading,
            products,
            productsLoading,
            searchProducts,
            searchProductBySku,
            salesLinkSettings,
            getBundleOptions
        }),
        [
            products,
            productsLoading,
            searchProducts,
            searchProductBySku,
            productTypes,
            productTypesLoading,
            currencies,
            currenciesLoading,
            query,
            page,
            totalPages,
            totalResults,
            goToPage,
            salesLinkSettings,
            getBundleOptions
        ]
    );

    return <SalesLinkDataContext.Provider value={value}>{children}</SalesLinkDataContext.Provider>;
};

export default SalesLinkDataProvider;

export const useSalesLinkDataContext = () => useContext(SalesLinkDataContext);

import React from 'react';
import { useRouter } from 'next/navigation';
import { Popover } from '@headlessui/react';
import Link from 'components/commercetools-ui/atoms/link';
import type { Reference } from 'types/reference';
import { useAccount } from 'frontastic';

interface Props {
    accountLinkLabel?: string;
    accountLink?: Reference;
    accountLogoutLabel?: string;
    wishlistLinkLabel?: string;
    wishlistLink?: Reference;
    resetFlag: () => void;
}

const LoggedIn: React.FC<Props> = ({ accountLinkLabel, accountLink, accountLogoutLabel, wishlistLinkLabel, wishlistLink, resetFlag }) => {
    const router = useRouter();
    const { logout } = useAccount();

    const handleLogout = async () => {
        await logout();

        router.replace('/login');
        resetFlag();
    };

    return (
        <div className="w-235 p-14">
            {accountLink && accountLinkLabel && (
                <Popover.Button className="w-full">
                    <Link
                        link={accountLink}
                        onClick={resetFlag}
                        className="mt-10 block w-fit hover:underline"
                        data-cy="profile-account-link"
                    >
                        {accountLinkLabel}
                    </Link>
                </Popover.Button>
            )}
            {wishlistLink && wishlistLinkLabel && (
                <Popover.Button className="w-full">
                    <Link
                        link={wishlistLink}
                        onClick={resetFlag}
                        className="mt-32 block w-fit hover:underline"
                        data-cy="profile-account-wishlist-link"
                    >
                        {wishlistLinkLabel}
                    </Link>
                </Popover.Button>
            )}
            {accountLogoutLabel && (
                <Popover.Button className="mt-32 block w-full pb-10 hover:underline" onClick={handleLogout} data-cy="logout">
                    <div className="w-fit">{accountLogoutLabel}</div>
                </Popover.Button>
            )}
        </div>
    );
};

export default LoggedIn;

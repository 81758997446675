import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import Button from 'components/commercetools-ui/atoms/button';
import HeaderLogo from 'components/commercetools-ui/organisms/header/header-logo';
import HeaderNavigationMobile from 'components/commercetools-ui/organisms/header/header-navigation/header-navigation-mobile';
import MenuIcon from 'components/commercetools-ui/organisms/header/menu-icon';
import type { HeaderProps } from 'components/commercetools-ui/organisms/header/types';
import UtilitySection from 'components/commercetools-ui/organisms/header/utility-section';
import SearchIcon from 'components/icons/search';
import { useFormat } from 'helpers/hooks/useFormat';
import { useBrandSettingsContext } from 'providers/brand-settings';

const CommercetoolsSearch = dynamic(() => import('components/commercetools-ui/atoms/search'));

const Header: React.FC<HeaderProps> = ({
    menuCategories,
    logo,
    emptyCartTitle,
    emptyCartImage,
    accountLabel,
    accountLabelLogged,
    accountLinkLabel,
    accountLink,
    accountLogoutLabel,
    wishlistLinkLabel,
    wishlistLink,
    cartLabel,
    searchLabel,
    menuLabel,
    socialMedia
}) => {
    const { formatMessage } = useFormat({ name: 'common' });
    const { searchSuggestionsEnabled } = useBrandSettingsContext();
    const [showSearch, setShowSearch] = useState(false);
    const showSearchField = () => {
        setShowSearch(true);
    };

    const hideSearchField = () => {
        setShowSearch(false);
    };

    const [showMenu, setShowMenu] = useState(false);
    const [flag, setFlag] = useState(false);

    const hideHeaderMenu = () => {
        setShowMenu(false);
    };

    const resetFlag = () => {
        setFlag(false);
    };

    const menuClick = () => {
        if (!flag) {
            setShowMenu(true);
        } else {
            setShowMenu(false);
        }

        setFlag(!flag);
    };

    const iconClasses = `h-2 w-full bg-icons-menu-bg 
                  before:absolute before:left-0 before:block before:h-2 before:w-full  before:bg-icons-menu-bg
                  before:transition-[top] before:transition-transform before:duration-[250ms]
                  after:absolute after:left-0 after:block after:h-2 after:w-full after:bg-icons-menu-bg
                  after:transition-[bottom] after:transition-transform after:duration-[250ms]`;

    return (
        <header className="relative h-fit w-full border-b-[1.5px] border-neutral-400 bg-white">
            <HeaderNavigationMobile
                links={menuCategories}
                hideHeaderMenu={() => hideHeaderMenu()}
                showMenu={showMenu}
                socialMedia={socialMedia}
            />
            <div
                aria-label="Top"
                className="relative flex h-header w-full items-center justify-between px-16 md:h-header-md md:px-24 lg:px-20 xl:px-40"
            >
                <div className="ml-0 flex w-full justify-start lg:ml-20 xl:ml-0 xl:w-fit">
                    <HeaderLogo
                        logo={logo}
                        imageClassName="flex h-29 w-144 justify-center text-center text-16  md:h-56 md:w-278 md:text-28"
                    />
                </div>
                <div className="z-[999] flex">
                    {searchSuggestionsEnabled && (
                        <Button
                            variant="ghost"
                            size="fit"
                            className={`mr-8 flex-col items-center hover:text-icons-search md:mr-12 ${showMenu ? 'hidden' : 'flex'}`}
                            onClick={showSearchField}
                            data-cy="search-icon"
                        >
                            <p className="flex h-header-icons items-center md:h-header-icons-md">
                                <SearchIcon className="w-24 md:w-32" />
                            </p>
                            <p className="mt-5 text-icons font-icons uppercase leading-tight md:text-icons-md">{searchLabel}</p>
                        </Button>
                    )}
                    <UtilitySection
                        emptyCartTitle={emptyCartTitle}
                        emptyCartImage={emptyCartImage}
                        accountLabel={accountLabel}
                        accountLabelLogged={accountLabelLogged}
                        cartLabel={cartLabel}
                        accountLinkLabel={accountLinkLabel}
                        accountLink={accountLink}
                        accountLogoutLabel={accountLogoutLabel}
                        wishlistLinkLabel={wishlistLinkLabel}
                        wishlistLink={wishlistLink}
                        resetFlag={resetFlag}
                    />
                    <Button
                        variant="ghost"
                        size="fit"
                        onClick={() => menuClick()}
                        title={formatMessage({ id: 'header.menu.open', defaultMessage: 'Open side menu' })}
                        className="ml-8 flex flex-col items-center hover:text-icons-menu md:ml-12"
                        data-cy="menu-icon"
                    >
                        <p className="flex h-header-icons items-center md:h-header-icons-md">
                            <MenuIcon
                                className={`${iconClasses}
                                ${
                                    showMenu
                                        ? 'before:top[50%] after:bottom[50%] bg-transparent before:rotate-45 after:-rotate-45'
                                        : 'before:top-0 after:bottom-0'
                                }`}
                                parentClassName="w-27 md:w-36 h-20 relative flex items-center"
                            />
                        </p>

                        {menuLabel && <p className="mt-5 text-icons font-icons uppercase leading-tight md:text-icons-md">{menuLabel}</p>}
                    </Button>
                </div>
            </div>

            <CommercetoolsSearch showSearch={showSearch} hideSearchField={hideSearchField} />
        </header>
    );
};
export default Header;

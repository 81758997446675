import type { ReactElement } from 'react';
import React from 'react';
import useClassNames from 'helpers/hooks/useClassNames';

interface Props extends React.ComponentProps<'input'> {
    className?: string;
    inputClassName?: string;
    wrapperClassName?: string;
    onChecked?: () => void;
    label?: string;
    srOnly?: boolean;
    icon?: ReactElement;
    heading?: string;
    description?: string;
}

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    onChecked?: () => void;
}

const Radio: React.FC<Props> = ({
    label,
    className = '',
    inputClassName = '',
    wrapperClassName = '',
    onChecked,
    children,
    checked,
    onChange,
    srOnly = false,
    ...props
}) => {
    const labelClassName = useClassNames([
        'relative bottom-10 flex cursor-pointer pl-25 after:absolute after:left-0 after:top-0 after:h-20 after:w-20 after:rounded-full after:border after:border-input-border',
        className
    ]);

    const inputClassNames = useClassNames(['peer absolute border-0 border-0 text-input-text opacity-0', inputClassName]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(e);
        onChecked?.();
    };

    return srOnly ? (
        <div className={wrapperClassName}>
            <input className={`peer sr-only ${inputClassName}`} type="radio" checked={checked} onChange={e => handleChange(e)} {...props} />
            <label className={className} htmlFor={props.id} data-cy={props.id}>
                {children}
            </label>
        </div>
    ) : (
        <label className={labelClassName} htmlFor={props.id}>
            <input
                style={{ backgroundImage: 'none' }}
                className={inputClassNames}
                type="radio"
                checked={checked}
                onChange={e => handleChange(e)}
                {...props}
            />
            <span className="absolute left-5 top-5 block size-10 rounded-full peer-checked:bg-input-border"></span>
            <p className="pl-8">{label}</p>
            {children}
        </label>
    );
};

export default Radio;

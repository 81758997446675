'use client';

import React, { useContext, useMemo } from 'react';
import type { Translations } from 'types/i18n';

export interface I18nContextShape {
    translations: Translations;
}

export const I18nContext = React.createContext({} as I18nContextShape);

const I18nProvider = ({ translations, children }: React.PropsWithChildren<I18nContextShape>) => {
    const contextValue = useMemo(() => ({ translations }), [translations]);
    return <I18nContext.Provider value={contextValue}>{children}</I18nContext.Provider>;
};

export default I18nProvider;

export const useI18n = () => useContext(I18nContext);

'use client';

import type { FC } from 'react';
import React from 'react';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useBrandSettingsContext } from 'providers/brand-settings';

const CopyrightText: FC = () => {
    const { copyrightText } = useBrandSettingsContext();
    return <Typography className="pb-20 pt-6 text-center text-8 text-white md:pb-0 md:text-left md:text-11">{copyrightText}</Typography>;
};

export default CopyrightText;

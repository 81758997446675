import React from 'react';

type Props = {
    className?: string;
};

const TrashIcon: React.FC<Props> = ({ className }: Props) => (
    <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
        <g clipPath="url(#clip0_237_2618)">
            <path d="M0.75 3.37H16.21" stroke="#414141" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M5.26988 0.75H11.6899C11.8999 0.75 12.0799 0.92 12.0799 1.14V3.37H4.87988V1.14C4.87988 0.93 5.04988 0.75 5.26988 0.75Z"
                stroke="#414141"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.7899 3.37H2.16992L2.88992 18.54H14.0699L14.7899 3.37Z"
                stroke="#414141"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M6.7002 6.53V15.49" stroke="#414141" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.2695 6.53V15.49" stroke="#414141" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_237_2618">
                <rect width="16.96" height="19.29" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default TrashIcon;

import type { FC, ReactNode } from 'react';
import React from 'react';

interface BundleItemContainerType {
    children: ReactNode;
    isAutoAdded?: boolean;
}

const BundleItemContainer: FC<BundleItemContainerType> = ({ children, isAutoAdded }) => {
    return (
        <div
            className="ml-32 flex max-w-full items-stretch justify-start gap-10 py-12 md:gap-15"
            data-cy={`${isAutoAdded ? '' : 'cart-item'}`}
        >
            <div className="max-w-full grow overflow-hidden">{children}</div>
        </div>
    );
};

export default BundleItemContainer;

import type { FC } from 'react';
import React from 'react';
import type { LineItem } from '@wilm/shared-types/cart/LineItem';
import Price from 'components/commercetools-ui/atoms/product-types/price';
import ProductName from 'components/commercetools-ui/atoms/product-types/product-name';
import Quantity from 'components/commercetools-ui/atoms/product-types/quantity';
import DelegateSelection from 'components/commercetools-ui/organisms/cart/components/delegate-selection';

interface TrainingCartItemProps {
    item: LineItem;
    learningMode: { label: string; value: string; hidden: boolean };
    startDate: { label: string; value: string; hidden: boolean };
    location: { label: string; value: string; hidden: boolean };
    counterClassName: string;
    updateCartItem?: (newQuantity: number) => Promise<void>;
    removeItem?: (lineItemId: string) => void;
    hideAttributes?: boolean;
    disabledQuantity?: boolean;
    showPrice?: boolean;
    showQuantity?: boolean;
    showQuantityEdit?: boolean;
    inventoryCheckError?: string;
    showDelegateSelection?: boolean;
    hasDelegateErrors: boolean;
}

const TrainingCartItem: FC<TrainingCartItemProps> = ({
    item = {} as LineItem,
    learningMode,
    startDate,
    location,
    counterClassName = '',
    updateCartItem = () => {},
    removeItem,
    hideAttributes = false,
    disabledQuantity = false,
    showPrice = true,
    showQuantity = false,
    showQuantityEdit = true,
    inventoryCheckError,
    showDelegateSelection,
    hasDelegateErrors
}) => (
    <>
        <ProductName lineItemId={item.lineItemId} removeItem={removeItem} productName={item.name!} />

        {/* Price */}
        {showPrice && <Price price={item.price as number} discountedPrice={item.discountedPrice as number} />}

        {/* Quantity */}
        <Quantity
            count={item.count ?? 1}
            counterClassName={counterClassName}
            updateCartItem={updateCartItem}
            disabledQuantity={disabledQuantity}
            showQuantityEdit={showQuantityEdit}
            showQuantity={showQuantity}
            inventoryCheckError={inventoryCheckError}
        />

        {/* Delivery method */}
        {!hideAttributes && (
            <div className="mt-10">
                {!learningMode.hidden && (
                    <div>
                        <div>
                            <span className="text-md font-bold">{learningMode.label}: </span>
                            <span className="text-md">{learningMode.value}</span>
                        </div>
                    </div>
                )}

                {/* Start Date */}
                {!startDate.hidden && (
                    <div>
                        <div>
                            <span className="text-md font-bold">{startDate.label}: </span>
                            <span className="text-md">{startDate.value}</span>
                        </div>
                    </div>
                )}

                {/* Location */}

                {!location.hidden && (
                    <div>
                        <div>
                            <span className="text-md font-bold">{location.label}: </span>
                            <span className="text-md">{location.value}</span>
                        </div>
                    </div>
                )}
                {showDelegateSelection && (
                    <DelegateSelection itemId={item.lineItemId} qty={item.count} hasDelegateErrors={hasDelegateErrors} />
                )}
            </div>
        )}
    </>
);

export default TrainingCartItem;

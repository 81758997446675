import { format, subMonths } from 'date-fns';
interface DateSequences {
    year: number;
    month: number;
    day: number;
    hour: number;
    minute: number;
    second: number;
}

export const calculateAndFormatMembershipStartDate = (endDate: string | number | Date, monthsToSubstract: number) => {
    const startDate = endDate && monthsToSubstract && subMonths(new Date(endDate), monthsToSubstract);
    return startDate && format(startDate, 'dd/MM/yyyy');
};

export const formatDate = (date: string | number | Date) => {
    return format(new Date(date), 'dd/MM/yyyy');
};

export const getDateWithTimeZone = (timeZone: string, dateSequences: DateSequences) => {
    const { year, month, day, hour, minute, second } = dateSequences;
    const monthIndex = month - 1;

    const date = new Date(Date.UTC(year, monthIndex, day, hour, minute, second));

    const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
    const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
    const offset = utcDate.getTime() - tzDate.getTime();

    date.setTime(date.getTime() + offset);

    return date;
};

/**
 * Scroll to the first element with data-error="true" attribute or to the element with the provided id
 * @param errorElementId - id of the element to scroll to
 * @returns void
 * @example
 * scrollToError('errorElementId');
 * scrollToError();
 */

const scrollToError = (errorElementId?: string) => {
    if (typeof document !== 'object') {
        return;
    }
    const errorInput = errorElementId
        ? document.body.querySelector('#' + errorElementId)
        : document.body.querySelector('[data-error="true"]');
    errorInput?.scrollIntoView({ behavior: 'smooth' });
};

export default scrollToError;

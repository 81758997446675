import type { FC } from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, PlusIcon, MinusIcon } from '@heroicons/react/24/outline';
import useClassNames from 'helpers/hooks/useClassNames';
import type { AccordionProps } from '.';
import Typography from '../typography';

type AccordionButtonProps = AccordionProps & {
    open: boolean;
    buttonWrapperClassName?: string;
    buttonClassName?: string;
};

const AccordionButton: FC<AccordionButtonProps> = ({
    open,
    buttonWrapperClassName,
    buttonClassName,
    openSectionTitle,
    closedSectionTitle,
    collapsedLabel,
    variant,
    iconClassName
}) => {
    const buttonClassNames = useClassNames(['flex w-full justify-between', buttonClassName]);

    return (
        <Disclosure.Button className={`${buttonWrapperClassName} w-full`}>
            <div className={buttonClassNames}>
                <Typography className="self-center transition">{open ? openSectionTitle : closedSectionTitle}</Typography>
                <div className="flex items-center gap-8">
                    {!open && collapsedLabel && <p className="font-medium text-primary-black">{collapsedLabel}</p>}
                    {variant === 'arrow' && (
                        <ChevronDownIcon width={17.5} strokeWidth={1} className={`${open ? 'rotate-180' : ''} ${iconClassName}`} />
                    )}
                    {variant !== 'arrow' && !open && collapsedLabel && <PlusIcon width={17.5} strokeWidth={2} className={iconClassName} />}
                    {variant !== 'arrow' && open && collapsedLabel && <MinusIcon width={17.5} strokeWidth={2} className={iconClassName} />}
                </div>
            </div>
        </Disclosure.Button>
    );
};

export default AccordionButton;
